import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import 'bootstrap/dist/js/bootstrap.bundle';
import { AuthService } from '../../services/auth.service';
import { ModalService } from '../../services/modal.service';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import { GenericModalComponent } from "../generic-modal/generic-modal.component";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-subuser',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    CommonModule,
    GenericModalComponent
],
  templateUrl: './add-subuser.component.html',
  styleUrl: './add-subuser.component.scss'
})
export class AddSubuserComponent {
  constructor(private modalService:ModalService, private authService: AuthService,private _userService: UserService,private route: ActivatedRoute, private router: Router, private _sharedService: SharedService) { }
  
  accountUsers : any[] = [];
  inviteEmail: string = '';
  alertMessage = '';
  error = '';
  toRemoveUser: any = {};
  removeAlertMessage = '';
  removeError = '';
  private yesSub!: Subscription;

  ngOnInit(){
    this.reloadUsers();
    this._sharedService.setData({ title: "Account Users" });
    this.yesSub = this.modalService.yesClick.subscribe(_result=> this.deleteUser());
  }

  ngOnDestroy(){
    this.yesSub.unsubscribe();
  }

  removeUser(user: any){
    this.toRemoveUser = user;
  }

  get removeUserDisplayname(){
    let output = '';
    if(this.toRemoveUser.id != null){
      let first = this.toRemoveUser.firstName != null ? this.toRemoveUser.firstName : "";
      let last = this.toRemoveUser.lastName != null ? this.toRemoveUser.lastName : "";
      output = first + " " + last;
      if(output.trim() == ''){
        output = this.toRemoveUser.email;
      }
      output += "'s";
    }
    else{
      output = 'this';
    }
    
    return output;
  }

  reloadUsers(){
    this._userService.getAccountUsers().subscribe({
      next: (response) => {
        console.log(response);
        this.accountUsers = response;
      }, 
      error: (e) => {
        console.error(e);
      }
    });
  }
  
  deleteUser(){
    this.authService.removeSubAccount(this.toRemoveUser.id).subscribe({
      next: (_response) => {
        this.removeAlertMessage = 'Successfully removed the user from the account.';
        this.toRemoveUser = {};
        this.reloadUsers();
      }, 
      error: (e) => {
        console.error(e);
        this.removeError = "There was an issue removing the user from the account.";
      }
    });
  }

  addUser(){
    this.alertMessage = '';
    this.error = '';
    this.authService.addSubAccount(this.inviteEmail).subscribe({
      next: (response) => {
        console.log(response);
        this.alertMessage = 'Successfully sent the registration email to: ' + this.inviteEmail;
        this.inviteEmail = '';
        this.reloadUsers();
      }, 
      error: (e) => {
        console.error(e);
        this.error = "There was an issue adding the user to the account.";
      }
    });
  }
}
