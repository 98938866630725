<div class="mainContent">
    <div *ngIf="alertMessage != ''">
        <div [class]="'alert alert-bar ' + (alertError ? 'alert-danger' : 'alert-success' )" role="alert">
            <div>
            {{alertMessage}}
            </div>
            <span class="close-alert" (click)="alertMessage = ''">X</span>
        </div>
    </div>
    @for(storage of nonStorageUnitList; track storage.location.id){
    <div class="container gap-2">
        <div class="card">
            <div  class="storage-header">
                <div (click)="handleExpandClick(storage.location.id)" >
                    <div class="location-name">{{storage.location.name}}</div>
                    <div class="gray">{{storage.location.description}}</div>
                </div>
                <div>
                    <app-option-button [location]="true" [storageId]="storage.location.id" [storageBudList]="false" ></app-option-button>
                </div>
            </div>
            <div class="dividing-row"></div>
            <div class="storage-stats">
                <div>
                    <div class="stats-info">{{storage.inventoryCount}}</div>
                    <div class="gray stats-desc">Units</div>
                </div>
                <div>
                    <div class="stats-info"> N/A </div>
                    <div class="gray stats-desc">Air Quality</div>
                </div>
                <div>
                    <div class="stats-info">{{getNumber(storage.avgLastTemp)}}°</div>
                    <div class="gray stats-desc">Temperature</div>
                </div>
                <div>
                    <div class="stats-info">{{getNumber(storage.avgHumidity)}}%</div>
                    <div class="gray stats-desc">Humidity</div>
                </div>
            </div>
        </div>

        <div *ngIf="storage.location.id == selectedUnit" class="container d-flex flex-column gap-2">
            @for (unit of locationToUnitList.get(storage.location.id); track unit.id) {
                <div class="container unit" [routerLink]="'/storage-detail/' + unit.id" style="cursor:pointer">
                    <div class="row gap-1 w-100">
                        <div class="col-2">
                            <img src="assets/storage-unit.svg" />
                        </div>
                        <div class="col-7 d-flex gap-1">
                            <label class="unit header">{{unit.name}}</label>
                            <label class="unit subheader">{{unit.description}}</label>
                        </div>
                        <div class="col-2 container bottle-and-amount d-flex gap-1">
                            <img class="bottle-icon" src="assets/bottle-small.svg" />
                            <label class="bottle-amount">{{unit.wineCount}}</label>
                        </div>
                    </div>
                </div>
            }
        </div>

    </div>

    }
    <button type="button" class="btn cc-btn col-12 mt-3" routerLink="/location-create">Add Location</button>
    <button type="button" class="btn secondary-btn col-12 mt-3" routerLink="/storage-list">Manage Storage</button>
</div>
<app-generic-modal
    modalId="removeUnitModal"
    modalTitle="Remove this Location"
    modalBody="Are you sure you want to remove this location?"
    yesButtonText="Remove Location"
    noButtonText="Cancel"
    [displayNoButton]="true"
/>