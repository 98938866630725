import { Component } from "@angular/core";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { SharedService } from "../../services/shared.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { StorageService } from "../../services/storage.service";
import { CommonModule } from "@angular/common";
import { numberToLetter } from "../../utils";
import { BudType } from "../../enums/BudType";
import { OptionButtonComponent } from "../option-button/option-button.component";
import { SlotStatus } from "../../enums/SlotStatus";
import { forkJoin } from "rxjs";

@Component({
    selector: "app-storage-unit-buds-list",
    standalone: true,
    imports: [TranslatePipe, CommonModule, RouterModule, OptionButtonComponent],
    templateUrl: "./bud-config-list.component.html",
    styleUrl: "./bud-config-list.component.scss",
})
export class BudConfigListComponent {
    /** Page prefix for translation of this page. */
    public pagePrefix = "storage-unit-buds.";

    /** id representing this storage location or unit. */
    public storageId: number = -1;

    /** Storage location or unit object. */
    public storageLocation: any;

    /** Bud device object that is associated with this config list. */
    public budDevice: any;

    /** List containing BudType enum values of all the types that
     * the bud device for this page is associated with. */
    public budTypeList: any[] = [];

    /** Hashmap of 'x,y,z' coordinates representing slot locations
     * to bud node objects. */
    public budNodeHashmap: Map<string, any> = new Map();

    /** Hashmap of wines that occupy slots. The key is the
     * 'x,y,z' string representation of the coordinates, and
     * the value is the associated wine object attached to
     * those coordinates. We do not use the value here,
     * only the key such that we do not need to create new
     * backend functionality since we can just retrieve
     * this hashmap and see if a wine occupies a
     * given slot by querying whether the hashmap
     * has coordinates of a slot where are looking at. */
    public wineHashmap: Map<string, any> = new Map();
    public location: boolean = false;

    constructor(
        private _sharedService: SharedService,
        private _route: ActivatedRoute,
        private _storageService: StorageService,
        private translate: TranslateService,
        private _router: Router
    ) {}

    ngOnInit() {
        this.translate.get(this.pagePrefix + "page-header").subscribe((title) =>
            this._sharedService.setData({
                body: "",
                title,
                image: "",
                backFunction: true,
            })
        );

        // We check whether the last parameter of our URL
        // contains the string 'location' so we know whether
        // this page is for a storage location rather than
        // a storage unit.
        let locationString = String(
            this._route.snapshot.paramMap.get("location")
        );
        if (locationString == "true") {
            this.location = true;
        }
        this.storageId = Number(this._route.snapshot.paramMap.get("id") ?? "");

        this._storageService
            .getStorageLocation(String(this.storageId))
            .subscribe((response1) => {
                this.storageLocation = response1;

                forkJoin({
                    locationDevice: this._storageService.getLocationDevice(
                        this.storageId
                    ),
                    budNodeHashMap: this._storageService.getBudNodeHashMap(
                        this.storageId
                    ),
                    wineCoordsHashMap:
                        this._storageService.getWineCoordsHashMap(
                            this.storageId
                        ),
                }).subscribe(
                    ({ locationDevice, budNodeHashMap, wineCoordsHashMap }) => {
                        this.budDevice = locationDevice;

                        const budMapping: { [key: string]: BudType } = {
                            standardBud: BudType.STANDARD,
                            bottleBud: BudType.BOTTLE,
                            motionSensorBud: BudType.MOTION_SENSOR,
                            bulkBud: BudType.BULK,
                            chillerControlBud: BudType.CHILLER_CONTROL,
                            enviornmentalSensorBud:
                                BudType.ENVIORNMENTAL_SENSOR,
                            airScrubberBud: BudType.AIR_SCRUBBER,
                            humidifierBud: BudType.HUMIDIFIER,
                        };

                        (
                            Object.keys(budMapping) as Array<
                                keyof typeof budMapping
                            >
                        ).forEach((key) => {
                            if (this.budDevice[key] === 1) {
                                this.budTypeList.push(budMapping[key]);
                            }
                        });

                        Object.keys(budNodeHashMap).forEach((key) => {
                            this.budNodeHashmap.set(key, budNodeHashMap[key]);
                        });

                        Object.keys(wineCoordsHashMap).forEach((key) => {
                            this.wineHashmap.set(key, wineCoordsHashMap[key]);
                        });

                        if (this.budTypeList.length === 0) {
                            this.budTypeList.push(BudType.NO_DEVICES_DETECTED);
                        }
                    }
                );
            });
    }

    get depth() {
        return this.getNumberArray(this.storageLocation.depth);
    }

    get rows() {
        return this.getNumberArray(this.storageLocation.rows);
    }

    get columns() {
        return this.getNumberArray(this.storageLocation.columns);
    }

    containsBudNode(column: any, row: any, depth: any): boolean {
        return this.budNodeHashmap.has(column + "," + row + "," + depth);
    }

    containsWine(column: any, row: any, depth: any): boolean {
        return this.wineHashmap.has(column + "," + row + "," + depth);
    }

    getSlotStatus(column: any, row: any, depth: any) {
        if (this.containsWine(column, row, depth)) {
            return SlotStatus.FILLED;
        }
        return SlotStatus.EMPTY;
    }

    getNumberArray(numb: number) {
        return Array.from({ length: numb }, (_, i) => i + 1);
    }

    getColumnNumber(column: number) {
        return numberToLetter(column);
    }

    handleMapClick() {
        this._router.navigate(["storage-list/" + this.storageId + "/bud-map"]);
    }

    handleBudClick() {
        this._router.navigate([
            "/location-list/" +
                this.storageId +
                "/bud-device/" +
                this.budDevice.id +
                "/",
        ]);
    }
}
