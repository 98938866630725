import { Component, ElementRef, Input } from "@angular/core";
import { CommonModule, Location } from "@angular/common";
import { NgxToggleModule } from "@bobbyg603/ngx-toggle";
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from "@angular/forms";
import { WineService } from "../../services/wine.service";
import { WineDetailService } from "../../services/wine-detail.service";
import { WineDetailListComponent } from "../wine-detail-list/wine-detail-list.component";
import { UserService } from "../../services/user.service";
import { PurchaseLocationInformationComponent } from "../purchase-location-information/purchase-location-information.component";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-wine-consume",
    standalone: true,
    imports: [
        CommonModule,
        TranslatePipe,
        ReactiveFormsModule,
        NgxToggleModule,
        FormsModule,
        WineDetailListComponent,
        PurchaseLocationInformationComponent,
    ],
    templateUrl: "./wine-consume.component.html",
    styleUrl: "./wine-consume.component.scss",
})
export class WineConsumeComponent {

    /** List of wine to be used in detail service
     * to display in the consuem wine from
     * collection page. */
    @Input() listOfWines: any[] = [];

    @Input("wine") wine: any;
    @Input("producerId") producerId: number = -1;

    /** ngModels of inputs that we may eventually
    send to the backend after user presses
    given that required inputs are entered */
    public selectedWineFromCollection: any = null;

    public vintageDropDownOptions: string[] = [];
    public searchLocation: string = "";
    public winesInCollection: boolean = false;
    public userId: number = -1;

    /** If this boolean is true, then we will display
     * the Wine Information as shown in the
     * "Consume Wine - From Location" diagram
     * From the figma. */
    public purchasedAndConsumed = false;

    /** If this boolean is true, then we will display
     * the Wine Information as shown in the
     * "Consume Wine - From Collection" diagram
     * From the figma. */
    public fromMyCollection = false;

    /** This means a user has clicked one of the two
    consumption options. */
    public optionSelected = false;

    /** List containing references to the star html elements
    used for rating wine */
    public stars: ElementRef[] = [];

    /** The rating for a wine. */
    public selectedRating: number = 0;

    /** Form that contains all related info about the inputs
    for this component */
    public form!: FormGroup;

    /** Used for translation purposes
    check i18n folder for more info */
    public pagePrefix = "wine-consume.";

    /** Slot id associated with selected wine. */
    public slotId: number = 0;

    /** Radio button for collection wine source. */
    public collectionRadio!: HTMLInputElement;

    /** Radio button for purcahse and consume wine source. */
    public purchaseConsumeRadio!: HTMLInputElement;

    constructor(
        private fb: FormBuilder,
        private _location: Location,
        private _userService: UserService,
        private _wineService: WineService,
        private _wineDetailService: WineDetailService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        if (!this.purchasedAndConsumed) {
            this.form = this.fb.group({
                amount: [1, []],
                wineNotes: ["", []],
                wineNotesPriv: [false, []],
                rating: [0, []],
                tastingNotes: ["", []],
                tastingNotesPriv: [false, []],
                locationType: ["", []],
                purchaseDate: ["", []],
                price: ["0.00", []],
                volume: [750, []],
                purchaseNotes: ["", []],
                address: ["", []],
                vintage: [0, []],
            });
        } else {
            this.form = this.fb.group({
                amount: [1, [Validators.required]],
                wineNotes: ["", []],
                wineNotesPriv: [false, []],
                rating: [0, []],
                tastingNotes: ["", []],
                tastingNotesPriv: [false, []],
                locationType: ["", []],
                purchaseDate: ["", [Validators.required]],
                price: ["0.00", [Validators.required]],
                volume: [750, []],
                purchaseNotes: ["", []],
                address: ["", [Validators.required]],
                vintage: [0, [Validators.required]],
            });
        }

        this._wineDetailService.wineList = this.listOfWines;
        this._wineService
            .getVintagesFromWine(this.wine.name, this.producerId)
            .subscribe((response) => {
                let vintages = response as number[];
                for (let i = 0; i < vintages.length; i++) {
                    this.vintageDropDownOptions.push(String(vintages.at(i)));
                }
            });

        this._userService.getUser().subscribe({
            next: (response) => {
                this.userId = response.id;
            },
            error: (e) => {
                console.error(e);
            },
        });

        this.collectionRadio = document.getElementById(
            "collection"
        ) as HTMLInputElement;
        this.purchaseConsumeRadio = document.getElementById(
            "purchaseConsume"
        ) as HTMLInputElement;

            this._wineService.getSameWineNotConsumed(this.wine.id)
            .subscribe((response) => {
                let responseData: any[] = response;
                if (responseData.length != 0) {
                    this.winesInCollection = true;
                } else {
                    this.optionSelected = true;
                    this.purchasedAndConsumed = true;
                    this.fromMyCollection = false;
                    this.purchaseConsumeRadio.checked = true;
                    this.collectionRadio.disabled=true;
                    this.collectionRadio.style.backgroundColor="gray";
                }
            });
    }

    getNumberArray(numb: number) {
        return Array.from({ length: numb }, (_, i) => i + 1);
    }

    handlePurchaseClick() {
        this.purchasedAndConsumed = true;
        this.fromMyCollection = false;
        this.optionSelected = true;
        this.purchaseConsumeRadio.checked = true;
        this.collectionRadio.checked = false;
    }

    handleCollectionClick() {
        if (this.winesInCollection) {
            this.purchasedAndConsumed = false;
            this.fromMyCollection = true;
            this.optionSelected = true;
            this.collectionRadio.checked=true;
            this.purchaseConsumeRadio.checked=false;
        }
    }

    handleStarPress(event: Event) {
        let element = event.target as HTMLInputElement;
        let value = Number(element.value);
        for (let i = 0; i < value; i++) {
            let img = this.stars[0].nativeElement
                .querySelector("label")
                .querySelector("img") as HTMLImageElement;
            img.src = "assets/star-filled.svg";
        }
    }

    decrementWine() {
        if (Number(this.form.get("amount")?.value) > 1) {
            this.form
                .get("amount")
                ?.setValue(String(Number(this.form.get("amount")?.value) - 1));
        }
    }

    incrementWine() {
        if (Number(this.form.get("amount")?.value) < 99) {
            this.form
                .get("amount")
                ?.setValue(String(Number(this.form.get("amount")?.value) + 1));
        }
    }

    onSubmit() {
        // Information taht we will send
        // to the backend
        let amount = this.form.get("amount")?.value;
        let wineNotes = this.form.get("wineNotes")?.value;
        let wineNotesPrivate = this.form.get("wineNotesPriv")?.value;
        let rating = this.selectedRating;
        let tastingNotes = this.form.get("tastingNotes")?.value;
        let tastingNotesPrivate = this.form.get("tastingNotesPriv")?.value;
        let locationType = this.form.get("locationType")?.value;
        let purchaseDate = this.form.get("purchaseDate")?.value;
        let price = Number(this.form.get("price")?.value);
        let volume = Number(this.form.get("volume")?.value);
        let purchaseNotes = this.form.get("purchaseNotes")?.value;
        let producerId = this.producerId;
        let address = this.form.get("address")?.value;
        let vintage = this.form.get("vintage")?.value;
        let wineName = this.wine.name;
        let slotId = this.slotId;

        let wineConsumptionInfo: any = {
            amount,
            wineNotes,
            wineNotesPrivate,
            rating,
            tastingNotes,
            tastingNotesPrivate,
            locationType,
            purchaseDate,
            price,
            volume,
            purchaseNotes,
            producerId,
            address,
            vintage,
            wineName,
            slotId,
        };

        if (this.form.invalid) {
            this.form.markAllAsTouched();
        } else {
            this._wineService
                .postWineConsumption(wineConsumptionInfo)
                .subscribe((_response) => {
                    // NO OP
                });
        }

        this.goBack();
    }

    recieveSlotInfo($event: any) {
        this.slotId = $event;
        console.log($event);
    }

    goBack() {
        this._location.back();
    }
}