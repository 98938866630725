import { CommonModule, Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { WineFilterService } from '../../services/wine-filter.service';
import { WineService } from '../../services/wine.service';
import { SortedWineListComponent } from "../sorted-wine-list/sorted-wine-list.component";
import { WineCountFilterComponent } from '../wine-count-filter/wine-count-filter.component';

@Component({
  selector: 'app-browse-wine-list',
  standalone: true,
  imports: [CommonModule, RouterModule, ReactiveFormsModule, FormsModule, SortedWineListComponent,WineCountFilterComponent],
  templateUrl: './browse-wine-list.component.html'
})
export class BrowseWineListComponent {
  constructor(private route: ActivatedRoute,  private router: Router, private _sharedService: SharedService,private location: Location,private _wineService: WineService,private _wineFilterService: WineFilterService) { }

  public category: string = "";
  public selectionText: string = "";
  public value: string = "";
  public subValue: string = "";

  ngOnInit(): void {
    this.category = this.route.snapshot.paramMap.get("category") ?? "";
    this.selectionText = this.route.snapshot.paramMap.get("selectionText") ?? "";
    this.value = this.route.snapshot.paramMap.get("value") ?? "";
    this.subValue = this.route.snapshot.paramMap.get("subValue") ?? "";
    if(this.category == ""){
      this.router.navigate(['/browse-wine']);
    }
    this.filterService.resetData();
    //wait for both requests to finish
    //list of wines
    //map of wine Id to how many bottles exist in the inventory
    const browseRes = this._wineService.getBrowseWineList(this.category,this.value,this.subValue);
    const countsRes = this._wineService.getWineCounts();
    forkJoin([browseRes,countsRes]).subscribe({
      next:([res1,res2]) =>{
      this._wineFilterService.wineList = res1;
      this._wineFilterService.wineCounts = res2;
      this.filterService.filterWine();
    },
    error:(error)=>{
      console.log(error);
    }});

    this._sharedService.setData({ body: "", title: this.selectionText, image: "", backFunction: true });
  }

  get filterService(){
    return this._wineFilterService;
  }

  goBack():void{
    this.location.back();
  }
}
