import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import 'bootstrap/dist/js/bootstrap.bundle';

@Component({
  selector: 'app-profile-header-options',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './profile-header-options.component.html'
})
export class ProfileHeaderOptionsComponent {

  constructor(private router: Router){}
  @Input() blackIcon: boolean = false;

  logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expiration');
    this.router.navigate(['/login']);
  }
}
