import { Component } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { ActivatedRoute } from "@angular/router";
import { StorageService } from "../../services/storage.service";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { BudMappingService } from "../../services/bud-mapping.service";
import { WineGridBoxComponent } from "../wine-grid-box/wine-grid-box.component";
import { CommonModule, Location } from "@angular/common";
import { Subject } from "rxjs";

@Component({
    selector: "app-bud-mapping",
    standalone: true,
    imports: [CommonModule, TranslatePipe, WineGridBoxComponent],
    templateUrl: "./bud-mapping.component.html",
    styleUrl: "./bud-mapping.component.scss",
})
export class BudMappingComponent {
    public pagePrefix = "bud-mapping.";
    public storageLocation: any;
    public storageId: number = -1;
    public resetSub: Subject<void> = new Subject<void>();

    constructor(
        private _sharedService: SharedService,
        private _route: ActivatedRoute,
        private _storageService: StorageService,
        private translate: TranslateService,
        private _budMapService: BudMappingService,
        private _location: Location
    ) {}

    ngOnInit() {
        this.translate.get(this.pagePrefix + "page-header").subscribe((title) =>
            this._sharedService.setData({
                body: "",
                title,
                image: "",
                backFunction: true,
            })
        );
        this.storageId = Number(this._route.snapshot.paramMap.get("id") ?? "");

        this._budMapService.resetData();
        this._storageService
            .getStorageLocation(String(this.storageId))
            .subscribe((response) => {
                this.storageLocation = response;
                this._storageService
                    .getBudNodeHashMap(Number(this.storageId))
                    .subscribe((resp1) => {
                        this._budMapService.configureMappingService(resp1);
                        let numFakeNodesNeeded =
                            Number(this.storageLocation.rows) *
                                Number(this.storageLocation.columns) *
                                Number(this.storageLocation.depth) -
                            this._budMapService.budNodes.length;
                        this._storageService
                            .getFakeBudNodes(numFakeNodesNeeded)
                            .subscribe((resp2) => {
                                this._budMapService.insertFakeBudNodes(
                                    resp2
                                );
                            });
                    });
            });
    }

    get depth() {
        return Array.from(
            { length: Number(this.storageLocation.depth) },
            (_, i) => i + 1
        );
    }

    get activeBudSelection() {
        return this._budMapService.activeBudSelection;
    }

    onSelectedSlotEvent(slot: any) {
        this._budMapService.setSelectedSlot(slot);
    }

    handleConfirmedSelectionClick() {
        this._budMapService.confirmSelection();
    }

    handleMapClick() {
        this._budMapService.activateBudSelection();
    }

    cycleLeft() {
        this._budMapService.cycleLeft();
    }

    cycleRight() {
        this._budMapService.cycleRight();
    }

    handleSubmitClick() {
        let slotToBud: { [key: string]: number } = {};
        this._budMapService.slotToBud.forEach((value, key) => {
            slotToBud[key] = value;
        });
        let postObject = {
            slotToBud: slotToBud,
            budNodes: this._budMapService.budNodes,
            storageLocation: this.storageLocation,
        };
        this._storageService.postBudMap(postObject).subscribe((_response) => {
            // NO OP
        });
        this.goBack();
    }

    goBack() {
        this._location.back();
    }

    get currentBudNodeIndex() {
        return this._budMapService.currentBudNodeIndex;
    }

    get slotToBud() {
        return this._budMapService.slotToBud;
    }
}
