import { DecimalPipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { ActivityService } from "../../services/activity.service";
import { ScrollService } from "../../services/scroll.service";
import { SearchService } from "../../services/search.service";
import { SharedService } from "../../services/shared.service";
import { UserService } from "../../services/user.service";
import { WineService } from "../../services/wine.service";
import { ActivityListComponent } from "../activity-list/activity-list.component";
import { WineListComponent } from "../wine-list/wine-list.component";
import { LocalStorageService } from "../../services/local-storage.service";

@Component({
    selector: "app-home",
    standalone: true,
    imports: [
        DecimalPipe,
        WineListComponent,
        RouterModule,
        FormsModule,
        ActivityListComponent,
    ],
    templateUrl: "./home.component.html",
    styleUrl: "./home.component.scss",
})
export class HomeComponent {
    public winecount: number = 0;
    public featuredWines: any = [];
    public recentActivity: any = [];
    public wineName: string = "";

    //possibly change this from only showing the 3 most recent events
    public maxNumb = 5;

    constructor(
        private _searchService: SearchService,
        private _activityService: ActivityService,
        private router: Router,
        private _sharedService: SharedService,
        private _wineService: WineService,
        private _userService: UserService,
        private _scrollService: ScrollService,
        private _localService: LocalStorageService
    ) {}

    ngOnInit(): void {
        this._sharedService.setData({
            body: "Good Evening!",
            title: "Search for Wine",
            image: "header4.png",
        });
        this._userService.getUser().subscribe({
            next: (response) => {
                console.info(response);
            },
            error: (e) => {
                console.error(e);
            },
        });

        this._wineService.getWineCounts().subscribe({
            next: (response) => {
                this.winecount = Object.keys(response).length;
            },
            error: (e) => {
                console.error(e);
            },
        });

        this._activityService.getActivity(this.maxNumb).subscribe({
            next: (response) => {
                let combinedList: any[] = [];
                let recentlyViewedList: any[] =
                    this._localService.getRecentActivity(false);
                let uniqueWinesMap: Map<number, any> = new Map(); // Track unique wine entries by ID

                // Process budEvent wines (from API response)
                for (let row of response) {
                    row.source = "budEvent";
                    uniqueWinesMap.set(row.wine.id, row); // Store/Replace entry based on wine.id
                }

                // Process recently viewed wines (from local storage)
                for (let wine of recentlyViewedList) {
                    let now = new Date();
                    let timeViewed = wine.timeViewed as number;
                    let minuteDifference = Math.floor(
                        (now.getTime() - timeViewed) / (60 * 1000)
                    );

                    let wrapperObject = {
                        wine: wine,
                        minuteDifference: minuteDifference,
                        source: "recentlyViewed",
                    };
                    console.log(wrapperObject);

                    // Replace the old entry with the new one if it exists
                    uniqueWinesMap.set(wine.id, wrapperObject);
                }

                // Convert Map to array
                combinedList = Array.from(uniqueWinesMap.values());

                // Sort by most recent (smallest `minuteDifference` first)
                combinedList.sort(
                    (a, b) => a.minuteDifference - b.minuteDifference
                );

                this.recentActivity = combinedList;
                this._scrollService.setMax(combinedList.length);
            },
            error: (e) => {
                console.error(e);
            },
        });

        this._wineService.getFeatured().subscribe({
            next: (response) => {
                console.log(response);
                this.featuredWines = response;
            },
            error: (e) => {
                console.error(e);
            },
        });
    }

    onKeyDownEvent(_event: Event) {
        this.router.navigate(["/search-results"]);
    }

    get searchService(): SearchService {
        return this._searchService;
    }
}
