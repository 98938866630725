<div class="mainContent">
    <form [formGroup]="form">
        <div class="form-group">
            <label for="name">{{pagePrefix + 'labels.name' | translate}}</label>
            <input type="text" class="form-control" id="name" name="name" formControlName="name" [ngClass]="{'input invalid': displayErrorMessage}">
        </div>
        <div class="form-group">
            <label for="description">{{pagePrefix + 'labels.description' | translate}}</label>
            <input type="text" class="form-control" id="description" formControlName="description" name="description">
        </div>
        <div class="form-group">
            <label for="storageId">{{pagePrefix + 'labels.location' | translate}}</label>
            <select id="storageId" name="storageId" formControlName="storageId" class="form-select" [ngClass]="{'select invalid': displayErrorMessage}">
                @for(loc of locationList; track loc.id){
                    <option [value]="loc.id">{{loc.name}}</option>
                }
            </select>
        </div>
        <div class="form-check form-switch">
            <input id="customType" type="checkbox" role="switch" class="form-check-input"
                   [(ngModel)]="customType" name="customType" [ngModelOptions]="{standalone: true}">
            <label for="customType" class="form-check-label">{{pagePrefix + 'labels.custom-type' | translate}}</label>
        </div>
        <div *ngIf="customType" class="custom-position-layout">
            <label for="columns">{{pagePrefix + 'labels.columns' | translate}}</label>
            <label for="rows">{{pagePrefix + 'labels.rows' | translate}}</label>
            <label for="depth">{{pagePrefix + 'labels.depth' | translate}}</label>
            <div class="plus-minus-wrapper">
                <button type="button" (click)="decrimentNumber('columns')">
                    <img [alt]="'generic-text.minus' | translate" src="/assets/minus.svg"/>
                </button>
                <input type="number" id="columns" name="columns" [(ngModel)]="storageLocation.columns"
                       [ngModelOptions]="{standalone: true}" (keyup)="validateMin('columns')" />
                <button type="button" (click)="incrementNumber('columns')">
                    <img [alt]="'generic-text.plus' | translate" src="/assets/plus.svg"/>
                </button>
            </div>
            <div class="plus-minus-wrapper">
                <button type="button" (click)="decrimentNumber('rows')">
                    <img [alt]="'generic-text.minus' | translate" src="/assets/minus.svg"/>
                </button>
                <input type="number" id="rows" name="rows" [(ngModel)]="storageLocation.rows"
                       [ngModelOptions]="{standalone: true}" (keyup)="validateMin('rows')" />
                <button type="button" (click)="incrementNumber('rows')">
                    <img [alt]="'generic-text.plus' | translate" src="/assets/plus.svg"/>
                </button>
            </div>
            <div class="plus-minus-wrapper">
                <button type="button" (click)="decrimentNumber('depth');resetSelectedDepth();">
                    <img [alt]="'generic-text.minus' | translate" src="/assets/minus.svg"/>
                </button>
                <input type="number" id="depth" name="depth" [(ngModel)]="storageLocation.depth"
                       [ngModelOptions]="{standalone: true}" (keyup)="validateMin('depth');resetSelectedDepth();" />
                <button type="button" (click)="incrementNumber('depth');resetSelectedDepth();">
                    <img [alt]="'generic-text.plus' | translate" src="/assets/plus.svg"/>
                </button>
            </div>
        </div>
        <app-wine-grid-box [storageLocation]="storageLocation" [resetSub]="resetSelectedDepthSub"/>
        <div class="d-flex flex-column gap-2">
            <label class="error-message" *ngIf="this.displayErrorMessage">{{errorMessage}}</label>
            <button class="btn cc-btn save-button" (click)="saveUnit()">{{pagePrefix + 'save-button' | translate}}</button>
        </div>
    </form>
</div>
