import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  //maximum number of items to display on the page
  private maxDisplay = 10;
  //maximum number of items in the list
  private maxItems = 0;
  //emits next when the list should be refiltered
  refilter$ = new Subject<void>();
  constructor() {
    //no op
  }

  resetMax(){
    this.maxDisplay = 10;
    this.maxItems = 0;
  }

  setMax(newMax:number){
    this.maxItems = newMax;
  }

  get maxDisplayVal(){
    return this.maxDisplay;
  }

  handleScroll(){
    let watchElem = document.getElementById("appContainer");
    if(watchElem != null && (watchElem.scrollTop + watchElem.clientHeight + 100) >= watchElem.scrollHeight && this.maxItems > this.maxDisplay){
        this.maxDisplay += 10;
        this.refilter$.next();
    }
  }

}
