import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { SharedService } from '../../services/shared.service';
import { StorageService } from '../../services/storage.service';
import { GenericModalComponent } from "../generic-modal/generic-modal.component";
import { Subscription } from 'rxjs';
import { OptionButtonComponent } from "../option-button/option-button.component";

@Component({
  selector: 'app-storage-list',
  standalone: true,
  imports: [GenericModalComponent, CommonModule, RouterModule, OptionButtonComponent],
  templateUrl: './storage-list.component.html',
  styleUrl: './storage-list.component.scss'
})
export class StorageListComponent {
  storageList:any[] = [];
  constructor(private modalService:ModalService, private _sharedService:SharedService,private _storageService:StorageService){}
  selectedUnit:number = -1;
  alertMessage:string = '';
  alertError:boolean = false;
  private yesSub!: Subscription;
  ngOnInit()
  {
    this._sharedService.setData({ body: "", title: "Wine Storage" });
    this._storageService.getBrowseList().subscribe((results)=>{
      this.storageList = results;
    });
    this.yesSub = this.modalService.yesClick.subscribe(result=> this.handleModalClose(result));
  }

  ngOnDestroy(){
    this.yesSub.unsubscribe();
  }

  getNumber(numb:any){
    if(numb == null){
      return '--';
    }
    return numb.toFixed(0);
  }

  handleModalClose(modalId:string){
    switch(modalId){
      case "exportWinesModal":
        this.exportWineList();
        break;
      case "removeUnitModal":
        this.removeUnit();
        break;

    }
  }

  removeUnit(){
    this.alertMessage = '';
    this._storageService.removeUnit(this.selectedUnit).subscribe({next: (_result)=>{
      this.alertMessage = 'Successfully removed the device';
      this.alertError = false;
      this._storageService.getBrowseList().subscribe((results)=>{
        this.storageList = results;
      });
    },error: (error)=>{
      console.error(error);
      this.alertMessage = 'Error encountered removing the device';
      this.alertError = true;
    }});
  }

  exportWineList(){
    this.alertMessage = '';
    this._storageService.exportWineList(this.selectedUnit).subscribe({next: (_result)=>{
      this.alertMessage = 'Successfully sent the winelist download email';
      this.alertError = false;
    },error: (error)=>{
      console.error(error);
      this.alertMessage = 'Error encountered sending the winelist download email';
      this.alertError = true;
    }});
  }

}
