@for(row of lazyActivityList; track row.nodeId) {
    @let wine = row.wine;
    <div class="card-item wine-card" (click)="handleRowClick(row)">
        <img class="wine-image" src="{{ wine.imagePath }}" />
        <div class="card-item-middle">
            <div>
                <div class="card-item-label wine-card-item-label">
                    {{wine.name}}
                </div>
                <div class="gray">
                    {{wine.area}}
                </div>
            </div>
            <div class="wine-color">
                <div [style.background]="wine.color" class="color-icon"></div>
                <span class="color-label">{{ wine.varietal }}</span>
            </div>
        </div>
        <div class="card-item-in-storage">
            <div class="notificationBottleCountContainer">
                <div class="bottleContainer">
                    <div bottleIcon="bottleIcon">
                        <img src="/assets/bottle-small.svg" />
                    </div>
                </div>
                <span class="bottleCount">{{filterService.getWineCount(row.wine.id)}}</span>
            </div>
            <div class="volume">({{ filterService.getVolumeString(row.wine.volume)}})</div>
        </div>
    </div>
    @if(displayTimeAgo){
        <div class="card-item gray-card">
            <div>
                {{row.eventCount}} events
            </div>
            <div>
                {{getTimeAgo(row.minuteDifference)}}
            </div>
        </div>
    }
    @else if(!$last) {
        <div class="dividing-row"></div>
    }
}
@empty {
    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
            No Recently Added Wines
        </div>
    </div>
}