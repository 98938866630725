import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const basicGuard: CanActivateFn = (_route, _state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if(authService.isIncomplete()){
    router.navigate(['/complete-profile']);
    return false;
  }
  else if (authService.isLoggedIn()) {
    return true;
  } 
  else {
    router.navigate(['/login']);
    return false;
  }
};
