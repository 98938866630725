import { numberToLetter } from "../utils";
import { BehaviorSubject } from "rxjs";


export class WineGridService {
    constructor() {
      //no op
    }

    // These are inputs from the comnponent
    // typescript file. Comments for these inputs
    // are located in the component typescript file.
    public amountToSelect!: BehaviorSubject<number>;
    public storageLocation!: any;
    public addToCollection!: boolean;
    public depthForBudSelection!: number;
    public budMapping!: boolean;
    public activeBudSelection!: boolean;
    public slotToBud!: Map<string, number>;
    public currentBudIndex!: number;
    public amountLimit: number = 1;

    /** The depth to view the the wine rack of a stroage
     * unit, which is selected by the user in the navigation
     * bar underneath this instance of wine grid component. */
    public selectedDepthFromNav: number = 1;

    /** The amount of slots the user has
     * selected in the add to collection
     * component. */
    public amountSelected: number = 0;

    /** Array of slots based on their string
     * representation. See key terms and their
     * definitions in bud mapping service
     * too see the meaning of 'slot string
     * representation'. */
    public slotsArray: string[] = [];

    /** Hashmap of slot string representation to
     * their location in slotsArray. See key terms
     * and their definitions in bud mapping service
     * for the definition of 'slot string representation'.*/
    public slotHashMap: Map<string, number> = new Map();

    /** Set that contrains string representations of
     * slots that are already occupied by a wine
     * bottle. Used for coloring slots grey in this
     * instance of wine grid component, and also making
     * them unavaliable to map bud nodes to during
     * a mapping session in bud mapping component.
     * See key terms and definitions in bud mapping
     * service for the meaning of 'mapping session'
     * and 'slot string representation'. */
    public occupiedSlotSet: Set<string> = new Set();

    getColumnNumber(column: number) {
        return numberToLetter(column);
    }

    getNumberArray(numb: number) {
        return Array.from({ length: numb }, (_, i) => i + 1);
    }

    increaseDepth() {
        if (this.selectedDepthFromNav < this.storageLocation.depth) {
            this.selectedDepthFromNav++;
        }
    }

    decreaseDepth() {
        if (this.selectedDepthFromNav > 1) {
            this.selectedDepthFromNav--;
        }
    }

    /** Helper function to create a key,value pair in slotHashmap,
     * and also adding that slot to slotsArray. See the long comment
     * above slotHashmap for more info. */
    createSlotKey(col: number, row: number, depth: number) {
        this.slotHashMap.set(`${col},${row},${depth}`, this.slotsArray.length);
        this.slotsArray.push(`${col},${row},${depth}`);
    }

    /** Helper function to determin whether a key is already
     * in slotHashMap. */
    containsSlotKey(col: number, row: number, depth: number): boolean {
        return this.slotHashMap.has(`${col},${row},${depth}`);
    }

    /** Helper function to determine whether a slot contains any bud
     * node during a mapping session in the bud mapping component.
     * See 'mapping session' listed under key terms and their
     * definitions for more info. */
    containsBudNode(col: number, row: number) {
        let selectedBudNode = this.slotToBud.get(
            `${col},${row},${this.depthForBudSelection}`
        );
        if (selectedBudNode == undefined) {
            return false;
        }
        return true;
    }

    /** Helper function to determine whether a slot contains the current bud
     * node in this mapping session. See bud mapping service for the definitions
     * of 'mapping service' and 'current bud index'. */
    containsBudNodeForCurrentIndex(col: number, row: number): boolean {
        if (!this.containsBudNode(col, row)) {
            return false;
        } else if (
            this.slotToBud.get(`${col},${row},${this.depthForBudSelection}`) ==
            this.currentBudIndex
        ) {
            return true;
        }
        return false;
    }

    /** Helper function to delete a key and its value from
     * slotsArray and slotHashmap. */
    deleteSlotKey(col: number, row: number, depth: number) {
        this.slotsArray.splice(
            Number(this.slotHashMap.get(`${col},${row},${depth}`)),
            1
        );
        this.slotHashMap.delete(`${col},${row},${depth}`);
    }

    /** Helper function to see whether the a slot string representation
     * is in the occupiedSlotSet. See 'slot string representation' under
     * key terms and their definitions in bud mapping service for more info. */
    isOccupiedSlot(col: number, row: number, depth: number): boolean {
        return this.occupiedSlotSet.has(`${col},${row},${depth}`);
    }

    resetData() {
        this.occupiedSlotSet = new Set();
        this.slotsArray = [];
        this.slotHashMap = new Map<string, number>();
        this.occupiedSlotSet = new Set<string>();
        this.selectedDepthFromNav = 1;
        this.amountSelected = 0;
    }

}
