<div class="mainContent">
  <div class="container d-flex flex-column gap-4">
    <div class="container storage-card flex-row">
      <div class="container d-flex flex-column gap-2" style="padding: 0px !important">
        <label class="storage-header">{{ storageLocation.name }}</label>
        <label class="storage-subheader">{{
          storageLocation.description
        }}</label>
      </div>
      <app-option-button [storageId]="storageId"></app-option-button>
    </div>

    <div class="container bud-card d-flex flex-column">
      <div class="d-flex flex-row w-100">
        <div class="d-flex flex-column w-100">
          <label class="bud-card-name">{{
            pagePrefix2 + budDeviceType | translate
          }}</label>
          <label class="bud-card-registration">{{
            budDevice.registeredIdentifier
          }}</label>
        </div>
        <div class="green-square"></div>
      </div>
      <div class="d-flex flex-column gap-2 w-100 mt-3">
        <div class="d-flex flex-row w-100">
          <label class="bud-card-label w-100">Last Communication</label>
          <label class="bud-card-detail w-10">{{
            budDevice.lastCommunication
          }}</label>
        </div>
        <div class="divider"></div>
        <div class="d-flex flex-row w-100">
          <label class="bud-card-label w-100">Label</label>
          <label class="bud-card-detail w-10">Placeholder</label>
        </div>
        <div class="divider"></div>
        <div class="d-flex flex-row w-100">
          <label class="bud-card-label w-100">Label</label>
          <label class="bud-card-detail w-10">Placeholder</label>
        </div>
        <div class="divider"></div>
        <div class="d-flex flex-row w-100">
          <label class="bud-card-label w-100">Label</label>
          <label class="bud-card-detail w-10">Placeholder</label>
        </div>
        <div class="divider"></div>
        <div class="d-flex flex-row w-100">
          <label class="bud-card-label w-100">Label</label>
          <label class="bud-card-detail w-10">Placeholder</label>
        </div>
      </div>
    </div>
  </div>
</div>
