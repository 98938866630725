import { CommonModule, KeyValue, Location } from "@angular/common";
import { Component, ElementRef } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { debounceTime, Subject } from "rxjs";
import { SearchService } from "../../services/search.service";
import { SharedService } from "../../services/shared.service";
import { WineFilterService } from "../../services/wine-filter.service";
import { WineService } from "../../services/wine.service";
import { SortedWineListComponent } from "../sorted-wine-list/sorted-wine-list.component";
import { LocalStorageService } from "../../services/local-storage.service";
import { ActivityListComponent } from "../activity-list/activity-list.component";
import { CacheService } from "../../services/cache.service";
import { UserService } from "../../services/user.service";

@Component({
    selector: "app-search-results",
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        SortedWineListComponent
    ],
    templateUrl: "./search-results.component.html",
    styleUrl: "./search-results.component.scss",
})
export class SearchResultsComponent {

    /** Used to add a debounce when typing new query. */
    private subject: Subject<string> = new Subject();

    /** Whether or not an advanced query is in progress. */
    public advancedQueryInProgress: boolean = false;

    /** Whether sorted wine list of backend wine objects is displayed. */
    public displayAdvancedQuery = false;

    /** Whether Or not Vial All Button is Displayed. */
    public viewAll: boolean = true;

    constructor(
        private elRef: ElementRef,
        private _wineFilterService: WineFilterService,
        private _searchService: SearchService,
        private _wineService: WineService,
        private _sharedService: SharedService,
        private location: Location,
        private _localService: LocalStorageService,
        private _userService: UserService,
        private _cacheService: CacheService
    ) {}

    ngOnInit(): void {

        this.cacheService.getCache().subscribe( (response) => {
            this.cacheProducerList = response['producerNameList'];
            this.cacheWineList = response['wineNameList'];
        });

        this.wineListRecentActivity = this.localService.getRecentActivity(false);

        // Query may not be empty due to the fact that a user
        // can return to the search page after clicking on a wine
        // card
        if (this.isQueryEmpty()) {
            this.wineFilterService.resetData();
            this.searchService.resetData();
            this.searchResults = this.firstFiveItemsFromSearchHistory;
            this.viewAll = true;
        } else {
            this.displayAdvancedQuery = true;
            this.wineService.getSearchResults(this.query).subscribe( (response) => {
                this.sortedProducerList = response["producerList"];
                this.sortedWineList = response["wineList"];
                this.wineListSearchResults = response["wineList"];
                this.wineFilterService.filterWine();
              });
        }


        this.elRef.nativeElement.querySelector("#input").focus();


        this.subject.pipe(debounceTime(1000)).subscribe((_value) => {
            if (!this.advancedQueryInProgress) {
                this.updateQuerySimple();
            }
        });

        this._sharedService.setData({
            body: "",
            title: "Search",
            image: "",
            backFunction: true,
        });
    }

    // this is called each time a user
    // updates their query without pressing
    // backspace
    updateQuerySimple() {

        this.displayAdvancedQuery = false;
        let filteredQuery = this.query
            .replace(this.searchService.filterExtraSpacesPattern, " ")
            .trim();

        // Conditional to handle weird bug
        // when user types a letter and quikcly deletes
        // which for some reason displays
        // all producers and wines on to
        // the search history
        if (filteredQuery.trim()!="") {
            this.searchService.searchFromCache(filteredQuery);
            this.wineFilterService.filterWine();
            this.previousQuery = this.query;
            this.query = filteredQuery;
        }
    }

    // this is called when the user presses enter
    // that displays advanced details of all
    // wines and producers form our search results
    updateQueryAdvanced() {
        if (!this.displayAdvancedQuery) {
            this.displayAdvancedQuery = true;
        } else {
            this.displayAdvancedQuery = false;
        }
    }

    onKeyUp(event: KeyboardEvent) {

        event.preventDefault();
        event.stopPropagation();

        if (event.key === 'Enter' && !this.displayAdvancedQuery) {

            this.updateQueryAdvanced();
            this.advancedQueryInProgress = true;
            this.wineService.getSearchResults(this.query).subscribe( (response) => {
                this.sortedProducerList = response["producerList"];
                this.sortedWineList = response["wineList"];
                this.wineListSearchResults = response["wineList"];
                this.wineFilterService.filterWine();
              });


        } else if (event.key === 'Enter' && this.displayAdvancedQuery)  {

            this.searchService.resetData();
            this.wineFilterService.resetData();
            this.advancedQueryInProgress = false;
            this.subject.next(this.query);

        } else {

            this.searchService.resetData();
            this.wineFilterService.resetData();
            this.displayAdvancedQuery=false;
            this.advancedQueryInProgress = false;

            if (this.query.trim() == "") {
                this.viewAll=true;
                this.searchResults = this.firstFiveItemsFromSearchHistory;
            } else if (
                this.searchService.isCurrentQueryDifferent() &&
                this.query.trim() != ""
            ) {
                this.subject.next(this.query);
            }

        }

    }

    handleResultClick(item: any) {

        this.localService.addToSearchCache(item);

        this.previousQuery = (this.query);
        this.query = item.name;

        this.wineFilterService.resetData();
        this.searchService.resetData();

        this.wineService.getSearchResults(item.name).subscribe( (response) => {
          this.sortedProducerList = response["producerList"];
          this.sortedWineList = response["wineList"];
          this.wineListSearchResults = response["wineList"];
          this.wineFilterService.filterWine();
        });

        this.updateQueryAdvanced();
    }

    handleViewAllClick() {
        this.searchResults = this._localService.getAllItemsForSearchCache();
        this.viewAll = false;
    }

    goBack(): void {
        this.searchService.resetData();
        this.location.back();
    }

    isRecentSearchLargerThanFive() {
        let list = this.localService.getAllItemsForSearchCache() as any[];
        return list.length>5;
    }

    handleDeleteClick(result:any) {
        this.searchService.resetData();
        this.localService.deleteFromSearchCache(result.name);
        if (!this.viewAll) {
            this.searchResults = this._localService.getFirstFiveItemsForSearchCache()
        } else {
            this.searchResults = this._localService.getFirstFiveItemsForSearchCache();
        }

    }

    isResultsEmpty(): boolean {
        return this.searchResults.length == 0;
    }

    isHistoryEmpty(): boolean {
        return this.allItemsFromSearchHistory.length == 0;
    }

    isQueryEmpty(): boolean {
        return this.searchService.query.trim() == "";
    }

    isQueryDifferent(): boolean {
        return this.searchService.isCurrentQueryDifferent();
    }

    // GETTERS

    get searchService(): SearchService {
        return this._searchService;
    }

    get wineService(): WineService {
        return this._wineService;
    }

    get wineFilterService(): WineFilterService {
        return this._wineFilterService;
    }

    get searchResults(): any[] {
        return this.searchService.searchResults;
    }

    get userService(): UserService {
        return this._userService;
    }

    get cacheService(): CacheService {
        return this._cacheService;
    }

    get localService(): LocalStorageService {
        return this._localService;
    }

    get previousQuery() {
        return this.searchService.previousQuery;
    }

    get firstFiveItemsFromSearchHistory() {
        return this.localService.getFirstFiveItemsForSearchCache();
    }

    get allItemsFromSearchHistory() {
        return this.localService.getAllItemsForSearchCache();
    }

    get query() {
        return this.searchService.query;
    }

    get wineListRecentActivity() {
        return this.wineFilterService.wineListRecentActivity;
    }

    get wineListSearchResults() {
        return this.wineFilterService.wineListSearchResults;
    }

    get sortedProducerList() {
        return this.searchService.sortedProducerList;
    }

    get sortedWineList() {
        return this.searchService.sortedWineList;
    }

    // SETTERS

    set sortedProducerList(value:any[]) {
        this.searchService.sortedProducerList = value;;
    }

    set sortedWineList(value:any[]) {
        this.searchService.sortedWineList = value;
    }

    set wineListRecentActivity(value:any[]) {
        this.wineFilterService.wineListRecentActivity = value;
    }

    set wineListSearchResults(value:any) {
        this.wineFilterService.wineListSearchResults = value;
    }

    set query(value:any) {
        this.searchService.query = value;
    }

    set previousQuery(value:any) {
        this.searchService.previousQuery = value;
    }

    set searchResults(value:any[]) {
        this.searchService.searchResults = value;
    }

    set cacheProducerList(value:any) {
        this.searchService.cacheProducerList = value;
    }

    set cacheWineList(value:any) {
        this.searchService.cacheWineList = value;
    }


}
