import { Injectable } from '@angular/core';
import { formatWineVolume } from '../utils';
import { ScrollService } from './scroll.service';

@Injectable({
  providedIn: 'root'
})
export class WineFilterService {

  constructor(private _scrollService: ScrollService) { }
  //Sort type (alpha,vintage,etc)
  public sortType: string = "";
  public sortTypeDisplay: string = "Sort By Default";
  //Pre filtered list of wines
  public wineList: any[] = [];
  // List of Wines for recent activity list
  public wineListRecentActivity: any[] = [];
  // List of Wines for Search Results
  public wineListSearchResults: any[] = [];
  //Counts of non consumed wines user has in their storage
  public wineCounts: any = {};
  //Contains all the filtered wines
  public filteredWineListPreDisplay: any[] = [];
  //Map of key to array of wines
  public filteredWineList: any = {};
  //User input filter string
  public filterText: string = "";
  public onlyInstock:boolean = false;

  resetData(){
    this.sortTypeDisplay = "Sort By Default";
    this.sortType = "";
    this.wineList = [];
    this.wineCounts = [];
    this.wineListSearchResults = [];
    this.filteredWineListPreDisplay = [];
    this.filteredWineList = {};
    this.filterText = "";
    this.onlyInstock = false;
  }

  getWineCount(wineId: number) : number{
    return wineId in this.wineCounts ? this.wineCounts[wineId] : 0;
  }

  getVolumeString(volume:number){
    return formatWineVolume(volume);
  }

  getWineKeys(){
    let keys = Object.keys(this.filteredWineList);
    if(this.sortType === 'vintage'){
      keys = keys.sort((a,b) => parseInt(a) < parseInt(b) ? -1 : 1);
    }
    else{
      keys = keys.sort((a,b) => a.localeCompare(b));
    }
    return keys;
  }

  getInStorageCount(){
    let count = 0;
    this.filteredWineListPreDisplay.forEach((wine: any) => {
      if(wine.id in this.wineCounts){
        count++;
      }
    });
    return count;
  }

  filterWine(){
    const filterVal = this.filterText.toLowerCase();
    let results: any[] = this.wineList.slice().filter(o =>
      //if only viewing items in stock
      (!this.onlyInstock || (o.id in this.wineCounts))
      && (
        //name contains text
        o.name.toLowerCase().includes(filterVal)
        //vintage contains text
        || o.vintage.toString().includes(filterVal)
        //region contains text
        || o.region.toLowerCase().includes(filterVal)
      )
    );
    results = results.sort((a,b) => a.name.localeCompare(b.name));
    this._scrollService.setMax(results.length);
    this.filteredWineListPreDisplay = results.slice();
    results = results.slice(0,this._scrollService.maxDisplayVal);
    this.filteredWineList = {};
    results.forEach(wine => {
      let key = "";
      switch(this.sortType){
        case "vintage":
          key = wine.vintage;
          break;
        case "region":
            key = wine.region;
            break;
        case "alpha":
        default:
          key = wine.name.substring(0,1).toUpperCase();
      }
      if(!(key in this.filteredWineList)){
        this.filteredWineList[key] = [];
      }
      this.filteredWineList[key].push(wine);
    });
  }

}

