import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";
import { StorageService } from "../../services/storage.service";
import { WineGridService } from "../../services/wine-grid.service";

@Component({
    selector: "app-wine-grid-box",
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: "./wine-grid-box.component.html",
    styleUrl: "./wine-grid-box.component.scss",
    providers: [WineGridService],
})
export class WineGridBoxComponent {
    /**
     *  ---KEY TERMS AND THEIR DEFINITIONS---
     *
     * BUD: a bud node (not a bud device). Functions
     * that contain the word 'bud' in them are for
     * bud node related logic.
     *
     */

    constructor(
        private _storageService: StorageService,
        private _wineGridService: WineGridService
    ) {}

    /** The storage location in which this wine grid component
     * will be used for. We will use its row, columns, depth
     * properties as well as other information from it in
     * this instance of wine grid component. */
    @Input("storageLocation") public storageLocation!: any;

    public storageLocation$ = new BehaviorSubject<any>(null);

    /** Boolean to add slot selection functionality
     * depending if this component is
     * being used for the add to collection
     * component */
    @Input("addToCollection") public addToCollection!: boolean;

    /** Component attribute to set the number of slots
     * to select depending on how much wine the user wants
     * to add in thier collection in the add to collection
     * component. */
    @Input("amountToSelect") public amountToSelect =
        new BehaviorSubject<number>(0);

    /** Component attribute for bud mapping component,
     * which creates multiple instances of wine grid
     * component but for each depth that a storage unit
     * has. */
    @Input("depthForBudSelection") public depthForBudSelection!: number;

    /** Component attribute to mark this wine grid component
     * instance as being used for the bud mapping component. */
    @Input("budMapping") budMapping!: boolean;

    /** Component attribute to let wine grid component
     * that bud selection is active (used for bud mapping
     * component). See key terms and definitions in
     * bud mapping component for more information. */
    @Input("activeBudSelection") public activeBudSelection: boolean = false;

    /** Component attribute to inform this instance of
     * wine grid component to color slots yellow if they
     * have been selected during a mapping session
     * in an instance of bud mapping component. See
     * key terms and definitions for definition
     * of what a 'mapping session' means. */
    @Input("slotToBud") public slotToBud!: Map<string, number>;

    /** Component attribute to inform this instance of
     * wine grid component what the current bud index is
     * of the current cycle in bud mapping component.
     * See key terms and their definitions in bud mapping
     * component for what 'current cycle' means. */
    @Input("currentBudIndex") public currentBudIndex!: number;

    /** The max amount of slots that the user
     * can select. */
    @Input("amountLimit") public amountLimit: number = 0;

    @Input("resetSub") public resetSub!: Subject<void>;

    /** An event emitter to inform the add to collection.
     * component a list of slots based on thier string.
     * representation that the user has selected. */
    @Output("selectedSlot") public selectedSlot: EventEmitter<any> =
      new EventEmitter<any>();

    /** The depth to view the the wine rack of a stroage
     * unit, which is selected by the user in the navigation
     * bar underneath this instance of wine grid component. */
    public selectedDepthFromNav: number = 1;

    /** The amount of slots the user has
     * selected in the add to collection
     * component. */
    public amountSelected: number = 0;

    /** Array of slots based on their string
     * representation. See key terms and their
     * definitions in bud mapping service
     * too see the meaning of 'slot string
     * representation'. */
    public slotsArray: string[] = [];

    /** Hashmap of slot string representation to
     * their location in slotsArray. See key terms
     * and their definitions in bud mapping service
     * for the definition of 'slot string representation'.*/
    public slotHashMap: Map<string, number> = new Map();

    /** Set that contrains string representations of
     * slots that are already occupied by a wine
     * bottle. Used for coloring slots grey in this
     * instance of wine grid component, and also making
     * them unavaliable to map bud nodes to during
     * a mapping session in bud mapping component.
     * See key terms and definitions in bud mapping
     * service for the meaning of 'mapping session'
     * and 'slot string representation'. */
    public occupiedSlotSet: Set<string> = new Set();

    ngOnInit() {
        this.resetSub.subscribe(() => {
            this._wineGridService.selectedDepthFromNav = 1;
        });

        if (this.storageLocation) {
            this.storageLocation$.next(this.storageLocation);
        }

        this.storageLocation$.subscribe((response1:any) => {
            this._wineGridService.resetData();
            this.storageLocation = response1;
            this._wineGridService.storageLocation = response1;
            if (Object.keys(response1).length !== 0) {
                this._storageService
                    .getOccupiedSlots(this._wineGridService.storageLocation.id)
                    .subscribe((response2) => {
                        let storageLocationSlots: any[] = response2;
                        for (let slot of storageLocationSlots) {
                            let slotStringRepresentation =
                                String(slot.positionX) +
                                "," +
                                String(slot.positionY) +
                                "," +
                                String(slot.positionZ);
                            this._wineGridService.occupiedSlotSet.add(
                                slotStringRepresentation
                            );
                        }
                    });
            }
        });

        this.amountToSelect.subscribe((response) => {
            this._wineGridService.amountLimit = response;
            while (
                this._wineGridService.amountSelected >
                this._wineGridService.amountLimit
            ) {
                let extraSlot: string = String(
                    this._wineGridService.slotsArray.at(
                        this._wineGridService.slotsArray.length - 1
                    )
                );
                this._wineGridService.slotsArray.splice(
                    this._wineGridService.slotsArray.length - 1,
                    1
                );
                this._wineGridService.slotHashMap.delete(extraSlot);
                this._wineGridService.amountSelected--;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case "amountLimit":
                        this._wineGridService.amountLimit =
                            changes[propName].currentValue;
                        this.amountLimit = changes[propName].currentValue;
                        break;
                    case "resetSub":
                        this.resetSub = changes[propName].currentValue;
                        break;
                    case "currentBudIndex":
                        this._wineGridService.currentBudIndex =
                            changes[propName].currentValue;
                        this.currentBudIndex = changes[propName].currentValue;
                        break;
                    case "slotToBud":
                        this._wineGridService.slotToBud =
                            changes[propName].currentValue;
                        this.slotToBud = changes[propName].currentValue;
                        break;
                    case "activeBudSelection":
                        this._wineGridService.activeBudSelection =
                            changes[propName].currentValue;
                        this.activeBudSelection =
                            changes[propName].currentValue;
                        break;
                    case "budMapping":
                        this._wineGridService.budMapping =
                            changes[propName].currentValue;
                        this.budMapping = changes[propName].currentValue;
                        break;
                    case "depthForBudSelection":
                        this._wineGridService.depthForBudSelection =
                            changes[propName].currentValue;
                        this.depthForBudSelection =
                            changes[propName].currentValue;
                        break;
                    case "addToCollection":
                        this._wineGridService.addToCollection =
                            changes[propName].currentValue;
                        this.addToCollection = changes[propName].currentValue;
                        break;
                    case "storageLocation":
                        this._wineGridService.storageLocation = changes[propName].currentValue;
                        this.storageLocation$.next(changes[propName].currentValue);
                        this.storageLocation = changes[propName].currentValue;
                        break;
                    default:
                        break;
                }
            }
        }
    }

    /** Depending on which component this
     * instance of wine grid component is being
     * used for, the state chooses one path
     * to take. */
    handleSlotClick(col: number, row: number) {
        if (this._wineGridService.addToCollection) {
            console.log(
                "Slot Click Coords: " +
                    col +
                    row +
                    this.getSelectedDepthFromnav()
            );
            this.selectSlotForWine(col, row);
        } else if (this._wineGridService.activeBudSelection) {
            this.selectSlotForBudNode(col, row);
        }
    }

    /** Creates or deletes a key, then emits an update.
     * If a slot already contains a key in the slot hashmap,
     * then we just delete that key, then we emit the list
     * of slots to add collection component with that slot
     * deleted from said list. */
    selectSlotForWine(col: number, row: number) {
        if (
            this.containsSlotKey(
                col,
                row,
                this._wineGridService.selectedDepthFromNav
            )
        ) {
            this._wineGridService.amountSelected--;
            this.deleteSlotKey(
                col,
                row,
                this._wineGridService.selectedDepthFromNav
            );
            this.selectedSlot.emit(this._wineGridService.slotsArray);
        } else if (
            this._wineGridService.amountSelected <
                this._wineGridService.amountLimit &&
            !this.isOccupiedSlot(
                col,
                row,
                this._wineGridService.selectedDepthFromNav
            )
        ) {
            this._wineGridService.amountSelected++;
            this.createSlotKey(
                col,
                row,
                this._wineGridService.selectedDepthFromNav
            );
            this.selectedSlot.emit(this._wineGridService.slotsArray);
        }
    }

    /** Selects a slot to map a bud node to during a mapping session
     * in bud mapping service. See 'mapping session' in the key terms
     * and definitions in the bud mapping service for more info.
     * Unlike selectSlotForWine, we only emit the coordinates
     * of one slot due to the unique functionality of bud mapping. */
    selectSlotForBudNode(col: number, row: number) {
        if (this.containsBudNodeForCurrentIndex(col, row)) {
            this.selectedSlot.emit(
                `${col},${row},${this._wineGridService.depthForBudSelection}`
            );
        } else if (!this.containsBudNode(col, row)) {
            console.log(
                `${col},${row},${this._wineGridService.depthForBudSelection}`
            );
            this.selectedSlot.emit(
                `${col},${row},${this._wineGridService.depthForBudSelection}`
            );
        }
    }

    getColumnNumber(column: number): string {
        return this._wineGridService.getColumnNumber(column);
    }

    getNumberArray(numb: number): number[] {
        return this._wineGridService.getNumberArray(numb);
    }

    increaseDepth() {
        this._wineGridService.increaseDepth();
    }

    decreaseDepth() {
        this._wineGridService.decreaseDepth();
    }

    createSlotKey(col: number, row: number, depth: number) {
        this._wineGridService.createSlotKey(col, row, depth);
    }

    containsSlotKey(col: number, row: number, depth: number): boolean {
        return this._wineGridService.slotHashMap.has(`${col},${row},${depth}`);
    }

    containsBudNode(col: number, row: number) {
        return this._wineGridService.containsBudNode(col, row);
    }

    containsBudNodeForCurrentIndex(col: number, row: number): boolean {
        return this._wineGridService.containsBudNodeForCurrentIndex(col, row);
    }

    deleteSlotKey(col: number, row: number, depth: number) {
        this._wineGridService.deleteSlotKey(col, row, depth);
    }

    isOccupiedSlot(col: number, row: number, depth: number): boolean {
        return this._wineGridService.isOccupiedSlot(col, row, depth);
    }

    getSelectedDepthFromnav() {
        return this._wineGridService.selectedDepthFromNav;
    }

    setSelectedDepthFromNav(value:number) {
        return this._wineGridService.selectedDepthFromNav = value;
    }

}
