export enum BudType {
    STANDARD = "standard",
    BOTTLE = "bottle",
    MOTION_SENSOR = "motion-sensor",
    BULK = "bulk",
    CHILLER_CONTROL = "chiller-control",
    ENVIORNMENTAL_SENSOR = "enviornmental-sensor",
    AIR_SCRUBBER = "air-scrubber",
    HUMIDIFIER = "humidifier",
    NOT_CONFIGURED = "not-configured",
    NO_DEVICES_DETECTED = "no-bud-devices-detected"
  }
