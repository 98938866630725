import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-option-button',
  standalone: true,
  imports: [RouterModule, CommonModule, TranslatePipe],
  templateUrl: './option-button.component.html',
  styleUrl: './option-button.component.scss'
})
export class OptionButtonComponent {

  public pagePrefix: string = "";
  public listURL: string = "";
  public locationOrStorageURL: string = "";
  @Input("storageBudList") public storageBudList: boolean = false;
  @Input("location") public location: boolean = false;
  @Input("setup") public setup: boolean = false;
  @Input("storageId") public storageId: number = -1;

  constructor(private translation: TranslateService, private _route: ActivatedRoute) { }

  ngOnInit() {

    let locationString = String(this._route.snapshot.paramMap.get("location") ?? "");
    if (locationString=="true") {
      this.location=true;
    }

    if (this.location) {
      this.pagePrefix = "option-button.location.";
      this.listURL = "/location-list/";
      this.locationOrStorageURL = "/location/";
    } else {
      this.pagePrefix = "option-button.storage-unit.";
      this.listURL = "/storage-list/";
      this.locationOrStorageURL = "/location/";
    }

  }

}
