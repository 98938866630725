import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ScrollService } from '../../services/scroll.service';
import { Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { WineFilterService } from '../../services/wine-filter.service';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
  selector: 'app-sorted-wine-list',
  standalone: true,
  imports: [CommonModule,RouterModule, ReactiveFormsModule, FormsModule ],
  templateUrl: './sorted-wine-list.component.html',
  styleUrl: './sorted-wine-list.component.scss'
})
export class SortedWineListComponent {

  // Search Results Component sets this to
  // true in order for this component
  // display the wine count of all the
  // wines that were returned from the search
  // result
  @Input() searchResults: boolean = false;

  @Input() searchResultsRecentActivity: boolean = false;



  constructor(private _scrollService: ScrollService,private _wineFilterService: WineFilterService,private _router: Router,private _localService: LocalStorageService) { }
  private filterSub!: Subscription;

  ngOnInit(): void {

    // Add this wine to both home page recent activity and
    // search page recent activity


    this._scrollService.resetMax();
    this.filterSub = this._scrollService.refilter$.subscribe({
      next:()=>{
        this._wineFilterService.filterWine();
      }
    });
  }

  ngOnDestroy(){
    this._scrollService.resetMax();
    this.filterSub.unsubscribe();
  }

  handleWineClick(wine:any) {
    if (this.searchResults && !this.searchResultsRecentActivity) {
      // we use this property so that we can display
      // the right icon in search history
      wine.timeViewed = new Date().getTime();
      this.localService.addToSearchCache({name: wine.name, wine: true})
      this.localService.addItemToRecentActivity(wine, false);
      this.localService.addItemToRecentActivity(wine, true);
    } else if (this.searchResults && this.searchResultsRecentActivity) {
      wine.timeViewed = new Date().getTime();
      this.localService.addItemToRecentActivity(wine, false);
      this.localService.addItemToRecentActivity(wine, true);
    }
    this.router.navigate(["/wine/" + wine.id])
  }

  get filterService(){
    return this._wineFilterService;
  }

  get router() {
    return this._router;
  }

  get localService() {
    return this._localService;
  }

}
