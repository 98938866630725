import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { StorageService } from '../../services/storage.service';
import { CommonModule } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-storage-device-config',
  standalone: true,
  imports: [CommonModule,FormsModule,TranslatePipe],
  templateUrl: './storage-device-config.component.html',
  styleUrl: './storage-device-config.component.scss'
})
export class StorageDeviceConfigComponent {
setUpBud() {
throw new Error('Method not implemented.');
}
  pagePrefix:string = "storage-device-config.";
  device: any= {};
  storageId!:number;
  location:boolean = false;
  setUp:boolean=false;
  storageLocation: any;

  //Key matches the property of the flag on the BudDevice
  budOptionsStorage: any[] = [
    {key:"standardBud",value:false},
    {key:"bottleBud",value:false},
    {key:"motionSensorBud",value:false},
    {key:"bulkBud",value:false},
    {key:"chillerControlBud",value:false}
  ];

  budOptionsLocation: any[] = [
    {key:"enviornmentalSensorBud",value:false},
    {key:"motionSensorBud",value:false},
    {key:"airScrubbdeviceerBud",value:false},
    {key:"humidifierBud",value:false}
  ];

  budOptions: any[] = [];

  constructor(private translate: TranslateService,private route: ActivatedRoute,private _sharedService: SharedService,private _storageService:StorageService,private router: Router){}

  ngOnInit(){
    this.storageId = Number(this.route.snapshot.paramMap.get("id") ?? "");
    let location = String(this.route.snapshot.paramMap.get("location") ?? false);
    let setUp = String(this.route.snapshot.paramMap.get("set-up") ?? "");
    if (location=="true") {
      this.location = true;
    } else {
      this.location = false;
    }

    if (setUp=="true") {
      this.setUp = true;
    } else {
      this.setUp = false;
    }

    if (!this.setUp) {
      this.translate.get(this.pagePrefix + 'page-header').subscribe((title)=>
        this._sharedService.setData({ body: "", title, image: "", backFunction: true })
      );
    } else {
      this.translate.get(this.pagePrefix + "setup-bud-page." + 'page-header').subscribe((title)=>
        this._sharedService.setData({ body: "", title, image: "", backFunction: true })
      );
    }

    this._storageService.getLocationDevice(this.storageId).subscribe((device)=>{
      console.log(device);
      this.device = device;
      this._storageService.getStorageLocation(String(this.storageId)).subscribe( (response) => {
        this.storageLocation = response;
      });
      if (this.location) {
        this.budOptionsLocation.forEach((row)=>{
          this.budOptions.push({key:row.key, value: this.device[row.key]});
        });
      } else {
        this.budOptionsStorage.forEach((row)=>{
          console.log(row);
          console.log(this.device[row.key]);
          this.budOptions.push({key:row.key, value: this.device[row.key]});
        });
      }

    });

  }

  saveUnit()
  {
    this.budOptions.forEach((row)=>{
      this.device[row.key] = row.value;
    });
    this._storageService.postDevice(this.device,this.storageId).subscribe({
      next: (_res)=>{
        this.router.navigate(['/storage-list']);
      },
      error: (error)=>{
        console.log(error);
      }
    });
  }
}
