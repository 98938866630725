import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { StorageService } from '../../services/storage.service';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';
import { OptionButtonComponent } from "../option-button/option-button.component";

@Component({
  selector: 'app-location-detail-list',
  standalone: true,
  imports: [GenericModalComponent, CommonModule, RouterModule, OptionButtonComponent],
  templateUrl: './location-list.component.html',
  styleUrl: './location-list.component.scss'
})
export class LocationListComponent {


  currentExpandedItem=-1;
  nonStorageUnitList: any = [];
  locationToUnitList: Map<any, any> = new Map();
  constructor(private sharedService:SharedService,private storageService:StorageService){}
  selectedUnit:number = -1;
  alertMessage:string = '';
  alertError:boolean = false;
  ngOnInit()
  {

    this.sharedService.setData({ body: "", title: "Storage Locations" });
    this.storageService.getBrowseNonStorageUnitList().subscribe((results)=>{
      this.nonStorageUnitList = results;

      for (let location of this.nonStorageUnitList) {
        this.storageService.getUnitListOfLocation(Number(location.location.id)).subscribe( (response) => {
            let unitList = response;
            this.locationToUnitList.set(location.location.id, unitList);
            for (let unit of unitList) {
              this.storageService.getStorageWineCount(Number(unit.id)).subscribe( (resp1) => {
                unit.wineCount=resp1;
              });
            }
        });
      }
    });
  }

  getNumber(numb:any){
    if(numb == null){
      return '--';
    }
    return numb.toFixed(0);
  }

  removeUnit(){
    this.alertMessage = '';
    this.storageService.removeUnit(this.selectedUnit).subscribe({next: (_result)=>{
      this.alertMessage = 'Successfully removed the storage location';
      this.alertError = false;
      this.storageService.getBrowseNonStorageUnitList().subscribe((results)=>{
        this.nonStorageUnitList = results;
      });
    },error: (error)=>{
      console.error(error);
      this.alertMessage = 'Error encountered removing the storage location';
      this.alertError = true;
    }});
  }

  handleExpandClick(id: number) {
    if (this.selectedUnit==id) {
      this.selectedUnit=-1;
    } else {
      this.selectedUnit=id;
    }
  }


}
