<div style="text-align: center">
  <h1>Search Label Unfinished Demo</h1>

  <div class="d-flex flex-column">
    <webcam
      [height]="500"
      [width]="500"
      [trigger]="triggerObservable"
      (imageCapture)="handleImage($event)"
      *ngIf="showWebcam"
      [allowCameraSwitch]="allowCameraSwitch"
      [switchCamera]="nextWebcamObservable"
      [videoOptions]="videoOptions"
      [imageQuality]="1"
      (cameraSwitched)="cameraWasSwitched($event)"
      (initError)="handleInitError($event)"
    ></webcam>

    <button class="btn cc-btn align-self-center w-50" (click)="triggerSnapshot();">Take A Snapshot</button>

    <img src />

  </div>
</div>
