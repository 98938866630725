import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScrollService } from '../../services/scroll.service';
import { WineDetailService } from '../../services/wine-detail.service';
import { Subscription } from 'rxjs';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { WineService } from '../../services/wine.service';
import { getSlotText } from '../../utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wine-detail-list',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './wine-detail-list.component.html',
  styleUrl: './wine-detail-list.component.scss'
})
export class WineDetailListComponent {

  constructor(private _router: Router, private _scrollService: ScrollService,private _wineDetailService: WineDetailService, private _wineService: WineService) { }
  private detailSub!: Subscription;
  @Input() wineConsumptionPage: boolean = false;
  @Output() selectedSlotEmitter: EventEmitter<any> = new EventEmitter<any>();
  public wineSelected = false;
  public selectedSlotId: number = 0;
  public selectedSlotIndex: number = -1;
  public selectedWineId: number = -1;

  ngOnInit(): void {
    this._scrollService.resetMax();

    this.detailSub = this._scrollService.refilter$.subscribe({
      next:()=>{
        this._wineDetailService.refreshWineList();
      }
    });
  }

  ngOnDestroy(){
    this._scrollService.resetMax();
    this.detailSub.unsubscribe();
  }

  get wineDetailService(){
    return this._wineDetailService;
  }

  get wineService(){
    return this._wineService;
  }

  slotToLetter(x : number, y : number) {
    return getSlotText({positionX : x, positionY : y});
  }

  handleSlotClick($index: number, wineId: number, slot: any) {
    if (this.selectedSlotId!=0) {
      let previousRadioClicked = document.getElementById("slot radio " + this.selectedSlotIndex + " " + this.selectedSlotId + " " + this.selectedWineId) as HTMLInputElement;
      previousRadioClicked.checked=false;
    }

    this.selectedSlotIndex = $index;
    console.log(slot);
    this.selectedSlotId = slot.id;
    this.selectedWineId = wineId;

    let currentRadioClicked = document.getElementById("slot radio " + this.selectedSlotIndex + " " + this.selectedSlotId + " " + this.selectedWineId) as HTMLInputElement;
    currentRadioClicked.checked=true;

    console.log(this.selectedSlotId);

    this.selectedSlotEmitter.emit(this.selectedSlotId);
  }

}
