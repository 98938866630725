<div class="mainContent">
    <div *ngIf="alertMessage != ''">
        <div [class]="'alert alert-bar ' + (alertError ? 'alert-danger' : 'alert-success' )" role="alert">
            <div>
            {{alertMessage}}
            </div>
            <span class="close-alert" (click)="alertMessage = ''">X</span>
        </div>
    </div>
    @for(storage of storageList; track storage.location.id){
        <div class="card">
            <div class="storage-header">
                <div class="header-text">
                    <div class="location-name">{{storage.location.name}}</div>
                    <div class="gray">{{storage.location.description}}</div>
                </div>
                <app-option-button [storageId]="storage.location.id" [location]="false"></app-option-button>
            </div>
            <div class="dividing-row"></div>
            <div class="storage-stats">
                <div>
                    <div class="stats-info">{{storage.slotCount}}</div>
                    <div class="gray stats-desc">Bottles</div>
                </div>
                <div>
                    <div class="stats-info">{{storage.inventoryCount}}</div>
                    <div class="gray stats-desc">Available</div>
                </div>
                <div>
                    <div class="stats-info">{{getNumber(storage.avgLastTemp)}}°</div>
                    <div class="gray stats-desc">Temperature</div>
                </div>
                <div>
                    <div class="stats-info">{{getNumber(storage.avgHumidity)}}%</div>
                    <div class="gray stats-desc">Humidity</div>
                </div>
            </div>
        </div>

    }
    <button type="button" class="btn cc-btn col-12 mt-3" routerLink="/storage-create">Add Storage Unit</button>
    <button type="button" class="btn secondary-btn col-12 mt-3" [routerLink]="'/location-list/browse/' + true"> Manage Locations</button>
</div>
<app-generic-modal
    modalId="exportWinesModal"
    modalTitle="Create a Wine List from Storage Unit?"
    modalBody="Click the button below to have a wine list export for this storage unit created for you. We will email your email address you signed up with an easy link to download your wine list."
    yesButtonText="Create and Send My Export"
    [displayNoButton]="false"
/>
<app-generic-modal
    modalId="removeUnitModal"
    modalTitle="Remove this Storage Unit?"
    modalBody="Are you sure you want to remove this storage unit?"
    yesButtonText="Remove Unit"
    noButtonText="Cancel"
    [displayNoButton]="true"
/>