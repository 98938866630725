import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class BudMappingService {
    constructor() {
        //no op
    }

    /**
     *
     * ---KEY TERMS AND THEIR DEFINITIONS---
     *
     * CURRENT CYCLE: the current state in which
     * bud selection is active, and the code is
     * currently waiting for user input on whether
     * or not to map a bud node (which has their
     * light activley blinking) to a slot.
     *
     * CURRENT MAPPING SESSION: an alternative
     * definition of current cycle.
     *
     * PREVIOUS MAPPING SESSION: a previous
     * momment in time where the user has
     * submitted a set of bud nodes and their
     * mapped bud nodes to the backend.
     *
     * CYCLE RIGHT: the action of ending the
     * current cycle, and pushing the code
     * into a new cycle for a bud node that
     * is one down further in budNodes. This
     * action is also triggered when the user
     * confirms their selection of the slot
     * that the current bud node will be mapped
     * to. This action will also be triggered
     * when the user presses on the right
     * arrow button.
     *
     * CYCLE LEFT: the same as cycle right
     * except it will move back one in budNodes,
     * and this action will not be triggered by
     * pressing the confirm selection button.
     * This action will be also be triggered
     * when the user presses on the left arrow
     * button.
     *
     * SELECTED SLOT EVENT: the event that triggers
     * in wine grid component when the user clicks
     * on a slot to map the bud node to in the
     * current mapping session.
     *
     * CURRENT BUD NODE INDEX: the index of the bud
     * node in budNodes that is currently in the process
     * of being mapped to a slot in the current cycle.
     *
     * SLOT STRING REPRESENTATION: slot are represented
     * by the coordinates in the wine grid component
     * in the form of 'x,y,z'.
     *
     * BUD: a bud node (not a bud device).
     *
     */

    /** Whether or not the user is allowed to
     * select slots to map bud nodes to. */
    public activeBudSelection = false;

    /** Map of string representation of slot coordinates
     * to the index of the bud node it is correlated to
     * in budNodes. */
    public slotToBud = new Map<string, number>();

    /** List of bud node objects that will be
     * mapped to slots. */
    public budNodes: any[] = [];

    /** Map of idnex of bud node in budNodes to
     * the string representation of the slot it
     * is mapped to. This map is used to keep
     * track of all bud nodes the user has selected,
     * along with that bud node's coordinates. */
    public selectedSlots = new Map<number, string>();

    /** The index of the bud node in budNodes that is
     * currently in the process of being mapped to a
     * slot by the user. */
    public currentBudNodeIndex: number = 0;

    /** The string representation of the slot coordinates
     * that either the user has selected for a bud node
     * in the current cycle, or the string representation
     * of the slot coordinates that was mapped to a bud node
     * by the user in a previous mapping session. */
    public selectedSlot: string = "";

    /** Whether or not the user has confirmed their selection
     * of the slot that they mapped the current cycle bud node
     * to. */
    public confirmed: boolean = false;

    /** Represents the numer of bud nodes that was mapped
     * in a previous mapping session. */
    public numberOfPrevMapped: number = 0;

    /** Properly sets up the mapping service in order to
     * account for previous mapping sessions. Pushes
     * bud nodes that were previously mapped for this
     * storage locatation to budNodes. */
    configureMappingService(prevMappedSlotToBud: any) {
        let firstCoords = "";

        for (const slot in prevMappedSlotToBud) {
            let node = prevMappedSlotToBud[slot];

            const numberPattern = /\d+/g;
            const matches = slot.match(numberPattern);
            // Convert the matched strings to numbers
            const numbers = matches ? matches.map(Number) : [];

            let coords = String(
                numbers[0] + "," + numbers[1] + "," + numbers[2]
            );

            this.slotToBud.set(coords, this.currentBudNodeIndex);
            this.budNodes.push(node);
            this.selectedSlots.set(this.currentBudNodeIndex, coords);
            this.currentBudNodeIndex++;

            if (firstCoords == "") {
                firstCoords = coords;
                this.selectedSlot = coords;
            }

            this.numberOfPrevMapped++;
        }
        this.currentBudNodeIndex = 0;
    }

    /** Inserts fake bud nodes returned by the backend to
     * budNodes. */
    insertFakeBudNodes(budList: any[]) {
        for (let i = 0; i < budList.length; i++) {
            this.budNodes.push(budList.at(i));
        }
    }

    /** Sets confirmed to true, and calls cycle right
     * in the event that a slot has not been selected
     * by the time the user presses the Confirm Selection
     * button on the front end. */
    confirmSelection() {
        if (this.selectedSlot != "") {
            this.confirmed = true;

            this.cycleRight();
            this.confirmed = false;
        }
    }

    /** Moves to a new state that will correlate to a
     * mapping session of the next bud node in
     * budNodes. See key terms and definitions
     * above in this file for more information. */
    cycleRight() {
        if (this.currentBudNodeIndex < this.budNodes.length - 1) {
            if (!this.confirmed) {
                this.slotToBud.delete(this.selectedSlot);
                this.selectedSlots.delete(this.currentBudNodeIndex);
            }

            this.activeBudSelection = true;
            this.currentBudNodeIndex += 1;

            if (
                this.currentBudNodeIndex <= this.numberOfPrevMapped &&
                this.selectedSlots.has(this.currentBudNodeIndex)
            ) {
                this.selectedSlot = String(
                    this.selectedSlots.get(this.currentBudNodeIndex)
                );
            } else {
                this.selectedSlot = "";
            }

        }
    }

    /** Moves to a new state that will correlate to a
     * mapping session of the previous bud node in
     * budNodes. See key terms and definitions
     * above in this file for more information. */
    cycleLeft() {
        if (!this.confirmed) {
            this.slotToBud.delete(this.selectedSlot);
            this.selectedSlots.delete(this.currentBudNodeIndex);
        }

        if (this.currentBudNodeIndex > 0) {
            this.activeBudSelection = true;
            this.currentBudNodeIndex -= 1;
            this.slotToBud.delete(
                String(this.selectedSlots.get(this.currentBudNodeIndex))
            );
            this.selectedSlots.delete(this.currentBudNodeIndex);
            this.selectedSlot = "";
        }
    }

    /** Called upon a selected slot event (see key terms and definitions
     * above in this file for more information). Sets the selected
     * slot to the one passed by the selected slot event, and adds
     * it to selectedSlots. If the current budNode index is already
     * in selectedSlots, this means that the user has mapped the bud
     * node in budNodes represented by the current bud index either
     * in a previous mapping session or if the user has mapped it before
     * during this current mapping session. This simialr logic is also
     * applied to slotToBud, where it updates slotToBud by mapping
     * the string representation of the slot that the user clicked
     * on to the bud node represented by the current bud node index by
     * a delete operation and a set operation in that order.
     *
     * This function is also used to handle logic for bud nodes
     * that were mapped in a previous mapping session.
     *
     *  */
    setSelectedSlot(slot: string) {
        if (
            !this.selectedSlots.has(this.currentBudNodeIndex) ||
            this.selectedSlot != ""
        ) {
            this.slotToBud.delete(this.selectedSlot);
            this.slotToBud.set(slot, this.currentBudNodeIndex);
            this.selectedSlots.delete(this.currentBudNodeIndex);
            this.selectedSlots.set(this.currentBudNodeIndex, slot);
            this.selectedSlot = slot;
        }
    }

    /** Called to set activeBudSelection to true. */
    activateBudSelection() {
        this.activeBudSelection = true;
    }

    /** Resets data so that the mapping service can be
     * used again incase it was used previously. */
    public resetData(): void {
        this.activeBudSelection = false;
        this.slotToBud = new Map<string, number>();
        this.budNodes = [];
        this.selectedSlots = new Map<number, string>();
        this.currentBudNodeIndex = 0;
        this.selectedSlot = "";
        this.confirmed = false;
    }
}
