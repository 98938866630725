<div class="mainContent">
  <div class="container d-flex flex-column gap-4">
    <div class="container d-flex flex-column gap-4">
      <div class="container storage-card flex-row">
        <div class="container d-flex flex-column header-text gap-2">
          <label class="storage-header">{{ storageLocation.name }}</label>
          <label class="storage-subheader">{{
            storageLocation.description
          }}</label>
        </div>
        <app-option-button [location]="location" [storageId]="storageId" [storageBudList]="!location" ></app-option-button>
      </div>
      <div *ngIf="!location" class="container">
        <div class="row">
          <div class="d-flex col-6">
            <button
              type="button"
              class="btn cc-btn"
              style="width: 100%"
              (click)="handleMapClick()"
            >
              {{ pagePrefix + "map-bud-sensors-button" | translate }}
            </button>
          </div>
          <div class="d-flex col-6">
            <button
              type="button"
              class="btn connect-hub-button"
              style="width: 100%"
            >
              {{ pagePrefix + "connect-hub-button" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="container d-flex flex-column buds-in-unit-card"
        style="padding: 15px"
      >
        <div class="container d-flex flex-column">
          <label class="buds-in-unit-label">{{
            pagePrefix + "buds-in-unit" | translate
          }}</label>
        </div>
        <div class="w-100" *ngFor="let budType of budTypeList">
          <div class="dividing-row"></div>
          <div (click)="handleBudClick()" class="d-flex justify-content-between w-100">
            <div
              class="container d-flex align-items-start justify-content-between"
              style="margin-top: 20px"
            >
              <label class="bud-unit-text">{{
                pagePrefix + budType | translate
              }}</label>
            </div>
            <div
              class="position-relative"
              style="padding-top: 20px; margin-right: 12px"
            >
              <div
                *ngIf="budType !== 'no-bud-devices-detected'"
                class="green-square"
              ></div>
              <div
                *ngIf="budType == 'no-bud-devices-detected'"
                class="red-square"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="!location"
        class="container d-flex align-items-start gap-1"
      >
        <img
          class="icon"
          src="assets/search2.svg"
          style="align-self: center"
          [alt]="pagePrefix + 'filter-list-placeholder' | translate"
        />
        <div class="text-input-container">
          <input
            name="filter"
            class="form-control"
            type="text"
            [placeholder]="pagePrefix + 'filter-list-placeholder' | translate"
          />
        </div>
      </div>

      <div *ngIf="!location">
        <div
          *ngFor="let z of depth"
          class="container d-flex flex-column buds-in-unit-card"
          style="padding: 15px"
        >
          <div class="container d-flex flex-column">
            <label class="buds-in-unit-label">
              {{ (pagePrefix + "depth" | translate) + " " }}{{ z }}
            </label>
          </div>
          <div *ngFor="let column of columns" class="w-100">
            <div *ngFor="let row of rows" class="w-100">
              <div class="dividing-row"></div>
              <div
                class="container align-items-start justify-content-between d-flex"
              >
                <div class="row mt-3 w-100">
                  <div class="col-2">
                    <div
                      *ngIf="!containsWine(column, row, depth)"
                      class="slot-circle"
                    >
                      {{ getColumnNumber(column) }}{{ row }}
                    </div>
                    <div
                      *ngIf="containsWine(column, row, depth)"
                      class="slot-circle occupied"
                    >
                      {{ getColumnNumber(column) }}{{ row }}
                    </div>
                  </div>
                  <div class="col-10 align-content-center">
                    <label
                      class="depth-list-item-text"
                      *ngIf="!containsBudNode(column, row, depth)"
                      >{{
                        (pagePrefix + "not-configured" | translate) +
                          ", " +
                          (pagePrefix + getSlotStatus(column, row, depth)
                            | translate)
                      }}</label
                    >
                    <label
                      class="depth-list-item-text"
                      *ngIf="containsBudNode(column, row, depth)"
                      >{{
                        (pagePrefix + "active" | translate) +
                          ", " +
                          (pagePrefix + getSlotStatus(column, row, depth)
                            | translate)
                      }}</label
                    >
                  </div>
                </div>
                <div
                  class="position-relative align-self-center"
                  style="padding-top: 20px"
                >
                  <div
                    *ngIf="containsBudNode(column, row, depth)"
                    class="green-square"
                  ></div>
                  <div
                    *ngIf="!containsBudNode(column, row, depth)"
                    class="gray-square"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button type="button" class="btn connect-hub-button" style="width: 100%" [routerLink]="'/storage-device-config/' + storageId + '/location/' + '/' + location + '/' + '/setup/' + true">
      {{ pagePrefix + "setup-new-bud-button" | translate }}
    </button>
  </div>
</div>
