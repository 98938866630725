<div class="mainContent">

    <div class="container d-flex flex-column gap-4">

        <div *ngIf="setUp" class="container storage-card flex-row">
            <div class="container d-flex flex-column gap-2">
              <label class="storage-header">{{ storageLocation.name }}</label>
              <label class="storage-subheader">{{
                storageLocation.description
              }}</label>
            </div>
            <div class="position-relative container icon">
              <img class="icon position-absolute" src="assets/three-dots.svg" />
            </div>
          </div>

        <div class="container d-flex flex-column gap-4">

            <div class="container d-flex flex-column">
                <div *ngIf="!setUp" class="header-text-budtype">{{'storage-device-config.sub-header' | translate}}</div>
                <div *ngIf="setUp" class="header-text-setup w-100">{{'storage-device-config.setup-bud-page.sub-header' | translate}}</div>
            </div>

            <div class="contaoner d-flex flex-column">
                @for(checkboxGroup of budOptions; track $index){
                    <div class="checkbox-group">
                        <div class="check-wrapper">
                            <label [for]="checkboxGroup.key" [class.checkedBox]="checkboxGroup.value" (click)="checkboxGroup.value = !checkboxGroup.value" class="custom-box">
                                <img alt="check" *ngIf="checkboxGroup.value" src="/assets/check.svg" />
                            </label>
                            <input [id]="checkboxGroup.key" type="checkbox" style="display: none;"/>
                        </div>
                        <div>
                            <div class="sub-header-text">
                                {{(pagePrefix + checkboxGroup.key + ".header") | translate}}
                            </div>
                            <div class="detail-text">
                                {{(pagePrefix + checkboxGroup.key + ".description") | translate}}
                            </div>
                        </div>
                    </div>
                }
            </div>

        </div>


    <div>
        <button *ngIf="!setUp" class="btn cc-btn save-button" style="width:100%" (click)="saveUnit()">{{'storage-device-config.save-unit' | translate}}</button>
        <button *ngIf="setUp" class="btn cc-btn save-button" style="width:100%" (click)="setUpBud()">{{'storage-device-config.setup-bud-page.page-header' | translate}}</button>
    </div>

    </div>

</div>