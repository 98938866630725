<div class="mainContent">
  <div class="searchContainer">
    <div class="input-group mb-3 input-group-lg searchBar">
      <div class="input-group-prepend">
        <button class="btn btn-lg" type="button">
          <img src="/assets/search.svg" class="search-svg" />
        </button>
      </div>
      <input
        #input
        name="input"
        id="input"
        [(ngModel)]="query"
        (keyup)="onKeyUp($event)"
        type="text"
        class="form-control"
        placeholder="{{ query }}"
        aria-label="search"
        style="gap: 16px"
      />
    </div>
  </div>
  <div
    class="container search-history"
    *ngIf="!isHistoryEmpty() && isQueryEmpty()"
  >
    Recent Search History
  </div>
  <div *ngIf="!displayAdvancedQuery && !isResultsEmpty()" class="result-list-container container">
    <div class="w-100" *ngFor="let result of searchResults">
      <div class="d-flex justify-content-between flex-row w-100">
        <div class="result-card clickable">
          <div (click)="handleResultClick(result)">
            <img
              *ngIf="result.wine"
              src="/assets/bottle-small.svg"
              class="icon"
            />
            <img
              *ngIf="!result.wine"
              src="/assets/search-producer.svg"
              class="icon"
            />
            {{ result.name }}
          </div>
        </div>
        <div class="clickable" *ngIf="isQueryEmpty()" (click)="handleDeleteClick(result)">
          <img src="/assets/delete-history.svg" class="icon" />
        </div>
      </div>
      <div class="dividing-row"></div>
    </div>
  </div>
  <div
    (click)="handleViewAllClick()"
    class="view-all"
    *ngIf="isQueryEmpty() && viewAll && isRecentSearchLargerThanFive()"
  >
    View All
  </div>
  <div *ngIf="viewAll && isQueryEmpty()">
    <app-sorted-wine-list
      [searchResults]="true"
      [searchResultsRecentActivity]="true"
    >
    </app-sorted-wine-list>
  </div>
  <div *ngIf="displayAdvancedQuery">
    <div class="producer-header">
      {{ searchService.sortedProducerList.length }} Producers
    </div>

    <div style="margin-bottom: 15px">
      @for(producer of searchService.sortedProducerList; track $index) {
        <div *ngIf="!$first && !searchResults" class="dividing-row"></div>
        browse-wine-list/producers
        <div class="card-item wine-card" [routerLink]="'/browse-wine-list/producers/' + producer.name + '/' + producer.id">
          <img class="wine-image" src="{{ producer.imagePath }}" />
          <div class="card-item-middle">
            <div>
              <div class="card-item-label wine-card-item-label">
                {{ producer.name }}
              </div>
              <div class="gray">
                {{ producer.address.city + ", " + producer.address.country }}
              </div>
              <div>Label not implemented</div>
            </div>
          </div>
        </div>
        <div class="dividing-row"></div>
        }
    </div>
    <app-sorted-wine-list [searchResults]="true"> </app-sorted-wine-list>
  </div>
</div>
