import { CommonModule, Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { StorageService } from '../../services/storage.service';


@Component({
  selector: 'app-location-detail',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './location-detail.component.html',
  styleUrl: './location-detail.component.scss'
})
export class LocationDetailComponent {

  constructor(private route: ActivatedRoute,private fb: FormBuilder,private _sharedService: SharedService, private _storageService: StorageService, private location: Location){}


  ngOnInit(): void {
    this._sharedService.setData({ body: "", title: "Manage Location", image: "", backFunction: false });
  }

  handleSubmit(name: string, description: string) {
    let location: any = {name, description};
    this._storageService.postNewLocation(location).subscribe({
      next: (_response) => {
        this.location.back();
      },
      error: (_e) => {
        console.log(description);
      }
    });
  }



}
