<div class="mainContent">
  <div class="container d-flex flex-column gap-4">
    <div class="container d-flex storage-card gap-2">
      <label class="storage-header">{{ storageLocation.name }}</label>
      <label class="storage-subheader">{{ storageLocation.description }}</label>
    </div>

    <div *ngIf="!activeBudSelection" class="container d-flex flex-column gap-2">
      <label class="prompt-header">{{
        pagePrefix + "map-prompt.header" | translate
      }}</label>
      <label class="prompt-description">{{
        pagePrefix + "map-prompt.description" | translate
      }}</label>
      <button (click)="handleMapClick()" class="btn cc-btn">
        {{ pagePrefix + "buttons.start-mapping" | translate }}
      </button>
    </div>

    <div *ngIf="activeBudSelection" class="container d-flex flex-column gap-2">
      <label class="prompt-header">{{
        pagePrefix + "select-prompt.header" | translate
      }}</label>
      <label class="prompt-description">{{
        pagePrefix + "select-prompt.description" | translate
      }}</label>
      <label style="color: red">{{
        "Debug: Current BudNode Index is " + currentBudNodeIndex
      }}</label>
      <div class="container d-flex flex-row" style="gap: 10px">
        <button (click)="handleConfirmedSelectionClick()" class="btn cc-btn">
          {{ pagePrefix + "buttons.select-slot" | translate }}
        </button>
        <div (click)="cycleLeft()" class="cycle-button align-self-center">
          <img src="assets/left-vector.svg" />
        </div>
        <div (click)="cycleRight()" class="cycle-button align-self-center">
          <img src="assets/right-vector.svg" />
        </div>
      </div>
    </div>

    <div class="container d-flex flex-column gap-2">
      <div>
        <label class="layout-header">{{
          pagePrefix + "storage-unit-layout" | translate
        }}</label>
      </div>
      <div class="d-flex flex-column gap-4">
        <div class="container d-flex flex-column" *ngFor="let z of depth">
          <label class="location-header">{{
            (pagePrefix + "depth" | translate) + " " + z
          }}</label>
          <app-wine-grid-box
            (selectedSlot)="onSelectedSlotEvent($event)"
            [slotToBud]="slotToBud"
            [activeBudSelection]="activeBudSelection"
            [depthForBudSelection]="z"
            [addToCollection]="false"
            [storageLocation]="storageLocation"
            [budMapping]="true"
            [resetSub]="resetSub"
          ></app-wine-grid-box>
        </div>
      </div>
    </div>

    <div class="container">
      <button
        class="btn cc-btn"
        id="debug"
        name="debug"
        (click)="handleSubmitClick()"
      >
        SUBMIT - DEBUG ONLY
      </button>
    </div>
  </div>
</div>
