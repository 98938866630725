<div class="mainContent">
  <div class="container wine-item">
    <div class="row">
      <div class="col-3">
        <img
          class="wine-image"
          src="{{ wine.imagePath }}"
          [alt]="pagePrefix + 'wine-image' | translate"
        />
      </div>
      <div class="col-9">
        <div class="container wine-item-text">
          <div class="wine-item-heading">{{ wine.name }}</div>
          <div class="wine-item-subheading">{{ wine.region }}</div>
          <div class="d-flex align-items-start gap-1 pt-2">
            <div [style.background]="wine.color" class="wine-color-icon"></div>
            <span class="wine-color-label">{{ wine.varietal }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form" #formDirective="ngForm">
    <div class="container d-flex flex-column mt-4">
      <label class="input-label mb-2">{{
        pagePrefix + "vintage-selection" | translate
      }}</label>
      <div class="container vintage-list mb-4">
        <div
          *ngFor="let year of vintages; let i = index"
        >
          <div
            (click)="vintageClick(year)"
            class="d-flex align-items-center vintage-button vintage-text"
          >
            <input id="vintage {{ year }}" type="radio" />
            <div class="input-label">{{ year }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-5 d-flex flex-column">
          <label class="input-label">{{
            pagePrefix + "amount" | translate
          }}</label>
          <div class="d-flex align-items-start mt-2">
            <div (click)="decrementWine()">
              <img
                class="number-input-icon"
                src="assets/subtract.svg"
                style="cursor: pointer"
                [alt]="pagePrefix + 'number-input-icon' | translate"
              />
            </div>
            <input
              name="amount"
              class="quantity-input"
              type="text"
              placeholder="1"
              formControlName="amount"
            />
            <div (click)="incrementWine()">
              <img
                class="number-input-icon"
                src="assets/add.svg"
                [alt]="pagePrefix + 'number-input-icon' | translate"
              />
            </div>
          </div>
        </div>
        <div class="col-7 d-flex flex-column">
          <label class="input-label" for="volume">{{
            pagePrefix + "volume" | translate
          }}</label>
          <select
            class="dropdown-input select-input mt-1"
            name="volume"
            formControlName="volume"
            required="true"
          >
            <option value="0" selected disabled>
              {{ pagePrefix + "volume" | translate }}
            </option>
            <option value="375">375 ml</option>
            <option id="default-volume" value="750">750 ml</option>
            <option value="1500">1500 ml</option>
            <option value="3000">3000 ml</option>
            <option value="6000">6000 ml</option>
          </select>
        </div>
      </div>
    </div>
    <div class="container d-flex flex-column gap-2 mt-4">
      <label class="input-label">{{
        pagePrefix + "wine-status.label" | translate
      }}</label>
      <div (click)="handleCollectionClick()" class="container check-box">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="collection"
            id="collection"
          />
          <label class="form-check-label input-label" for="flexRadioDefault1">
            {{ pagePrefix + "wine-status.collection.label" | translate }}
          </label>
          <br />
          <label class="input-sublabel">{{
            pagePrefix + "wine-status.collection.description" | translate
          }}</label>
        </div>
      </div>
      <div (click)="handleBoxClick()" class="container check-box">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="box" id="box" />
          <label class="form-check-label input-label" for="flexRadioDefault1">
            {{ pagePrefix + "wine-status.box.label" | translate }}
          </label>
          <br />
          <label class="input-sublabel">{{
            pagePrefix + "wine-status.box.description" | translate
          }}</label>
        </div>
      </div>
      <div (click)="handleTransitClick()" class="container check-box">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="transit"
            id="transit"
          />
          <label class="form-check-label input-label" for="flexRadioDefault1">
            {{ pagePrefix + "wine-status.transit.label" | translate }}
          </label>
          <br />
          <label class="input-sublabel">{{
            pagePrefix + "wine-status.transit.description" | translate
          }}</label>
        </div>
      </div>
      <div class="d-flex flex-column mt-4">
        <label class="input-label" for="location">
          {{ pagePrefix + "location" | translate }}
        </label>
        <select
          class="dropdown-input select-input mt-1"
          name="location"
          required="true"
          (change)="changeLocation($event)"
        >
          <option value="" disabled selected>
            {{ pagePrefix + "location" | translate }}
          </option>
          @for(location of locations; track $index){
          <option [value]="$index">
            {{ location.name }}
          </option>
          }
        </select>
      </div>
      <div *ngIf="storageLocation != null" class="d-flex flex-column mt-4">
        <label class="input-label" for="slotStorage">{{
          pagePrefix + "slot" | translate
        }}</label>
        <app-wine-grid-box
          (selectedSlot)="onSelectedSlotEvent($event)"
          [amountToSelect]="amountToSelect"
          [addToCollection]="true"
          [resetSub]="resetSub"
          [storageLocation]="storageLocation.getValue()"
          [budMapping]="false"
        ></app-wine-grid-box>
      </div>
      <div class="d-flex flex-column mt-4">
        <label class="input-label" for="wineNotes">{{
          pagePrefix + "wine-notes.label" | translate
        }}</label>
        <div class="d-flex flex-column mt-1">
          <div class="text-input-container">
            <textarea
              class="form-control"
              name="wineNotes"
              id="wineNotes"
              formControlName="wineNotes"
            ></textarea>
            <label class="wine-notes-sublabel mt-1">{{
              pagePrefix + "wine-notes.description" | translate
            }}</label>
          </div>
        </div>
        <div class="d-flex align-items-start mt-4" style="gap: 5px">
          <ngx-toggle
            id="wineNotesPriv"
            name="wineNotesPriv"
            [disabled]="false"
            formControlName="wineNotesPriv"
          >
          </ngx-toggle>
          <label class="toggle-label">{{
            pagePrefix + "privacy" | translate
          }}</label>
        </div>
      </div>
      <div class="dividing-row mb-3"></div>
      <app-purchase-location-information
        [form]="form"
        [purchase]="true"
      ></app-purchase-location-information>
    </div>
    <div>
      <span *ngIf="displayErrorMessage" style="color: red">{{
        pagePrefix + "error" | translate
      }}</span>
      <button type="submit" class="btn cc-btn w-100 mt-4">Add Bottle</button>
    </div>
  </form>
</div>
