import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WineService {

  private baseUrl = 'wine';

  constructor(private http: HttpClient) {
    //no op
  }

  getBrowseList(category: string, key: string, value:string): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/browse`,{params: {category,key,value}});
  }
  getBrowseWineList(category: string, value:string, subValue:string): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/browse-list`,{params: {category,value,subValue}});
  }
  getWineCounts(): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/get-wine-counts`,{params: {}});
  }
  getCollection(): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/collection`,{params: {}});
  }
  getFeatured(): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/featured`,{params: {}});
  }

  getWineDetails(id: number): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/details`,{params: {id}});
  }

  getSameWineNotConsumed(wineId: number): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/same-wine-in-collecion`,{params: {wineId}});
  }

  getSlotAndStorageForWine(wineId: number): Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/location-and-slot-for-wine`,{params: {wineId}});
  }

  postNewFavorite(wineId: number) : Observable<any>  {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/new-favorite`, wineId);
  }

  postFavoriteRemoval(wineId: number) : Observable<any>  {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/remove-favorite`, wineId);
  }

  getFavoriteStatus(wineId: number) : Observable<any>  {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/favorite-status`,{params: {wineId: wineId.toString()}});
  }

  getWineWishListStatus(wineId: number) : Observable<any>  {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/get-wish-list-status`, {params: {wineId}});
  }

  postWishListUpdate(wineId: number, add: boolean) {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/update-wish-list`, null, { params: { wineId: wineId.toString(), add: add.toString() }});
  }

  getSearchResults(query: string) : Observable<any>  {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/get-search-results`, {params: {query}});
  }

  postWineConsumption(info: any) : Observable<any>  {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/post-wine-consumption`, info);
  }

  getVintagesFromWine(wineName: any, producerId: any) : Observable<any>  {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/get-vintages-from-wine`, {params: {wineName, producerId}});
  }

  postAddToCollection(postRequestBody: any) : Observable<any>  {
    return this.http.post(`${environment.apiBase}${this.baseUrl}/post-add-to-collection`, postRequestBody);
  }

  getBottleCountFromWine(wineId:number) : Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/get-bottle-count-of-wine`, {params: {wineId}});
  }

  getSearchCache(accountId:number) : Observable<any> {
    return this.http.get(`${environment.apiBase}${this.baseUrl}/get-search-cache`, {params: {accountId}});
  }


}
