import { Injectable } from '@angular/core';
import { formatWineVolume } from '../utils';
import { ScrollService } from './scroll.service';

@Injectable({
  providedIn: 'root'
})
export class WineDetailService {

  constructor(private _scrollService: ScrollService) { }
  //Pre filtered list of wines
  public wineList: any[] = [];
  //Counts of non consumed wines user has in their storage
  public wineCounts: any = {};
  //Contains all the filtered wines
  public refreshedListPreDisplay: any[] = [];
  //Map of key to array of wines
  public refreshWineList: any = [];

  resetData(){
    this.wineList = [];
    this.wineCounts = [];
    this.refreshedListPreDisplay = [];
    this.refreshWineList = [];
  }

  getWineCount(wineId: number) : number{
    return wineId in this.wineCounts ? this.wineCounts[wineId] : 0;
  }

  getVolumeString(volume:number){
    return formatWineVolume(volume);
  }

  getInStorageCount(){
    let count = 0;
    this.refreshedListPreDisplay.forEach((wine: any) => {
      if(wine.id in this.wineCounts){
        count++;
      }
    });
    return count;
  }

  refreshList(){
    let results: any[] = this.wineList;
    this._scrollService.setMax(results.length);
    this.refreshedListPreDisplay = results.slice();
    results = results.slice(0,this._scrollService.maxDisplayVal);
    this.refreshWineList = [];
    results.forEach(wine => {
      this.refreshWineList.push(wine);
    });
  }
}

