import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class LocalStorageService {
    private maxItems = 20;
    private searchCacheKey = "wine_search_cache";

    constructor() {}

    // Add a new search item while ensuring uniqueness and correct order
    addToSearchCache(value: any): void {
        let storedList: any[] = JSON.parse(localStorage.getItem(this.searchCacheKey) || "[]");

        // Remove existing item with the same name
        storedList = storedList.filter(item => item.name !== value.name);

        // Add new search item at the beginning
        storedList.unshift(value);

        // Ensure we don't exceed max limit
        if (storedList.length > this.maxItems) {
            storedList = storedList.slice(0, this.maxItems);
        }

        // Save updated list
        localStorage.setItem(this.searchCacheKey, JSON.stringify(storedList));
    }

    // Delete an item from search cache by name
    deleteFromSearchCache(name: string): void {
        let storedList: any[] = JSON.parse(localStorage.getItem(this.searchCacheKey) || "[]");

        // Remove the item with the matching name
        storedList = storedList.filter(item => item.name !== name);

        // Save updated list
        localStorage.setItem(this.searchCacheKey, JSON.stringify(storedList));
    }

    // Retrieve all unique search items in most recent order
    getAllItemsForSearchCache(): any[] {
        return JSON.parse(localStorage.getItem(this.searchCacheKey) || "[]");
    }

    // Retrieve the first five unique search items in most recent order
    getFirstFiveItemsForSearchCache(): any[] {

        let storedList: any[] = JSON.parse(
            localStorage.getItem(this.searchCacheKey) || "[]"
        );
        return storedList.slice(0, 5);
    }

    // Clear the search cache
    clearSearchCache(): void {
        localStorage.removeItem(this.searchCacheKey);
    }

    addItemToRecentActivity(value: any, homepage: boolean): void {
        let key = homepage ? "homepage_recent_activity" : "search_recent_activity";
        let storedList: any[] = JSON.parse(localStorage.getItem(key) || "[]");

        // Ensure uniqueness based on 'name' instead of 'id'
        storedList = storedList.filter(item => item.name !== value.name);
        storedList.push(value);

        if ((storedList.length > 20 && !homepage) || (storedList.length > 5 && homepage)) {
            storedList.shift();
        }

        storedList.reverse();
        localStorage.setItem(key, JSON.stringify(storedList));
    }

    getRecentActivity(homepage: boolean): any[] {
        let key = homepage ? "homepage_recent_activity" : "search_recent_activity";
        return JSON.parse(localStorage.getItem(key) || "[]").reverse();
    }
}
