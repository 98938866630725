import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { SharedService } from "../../services/shared.service";
import { UserService } from "../../services/user.service";
import { WineDetailService } from "../../services/wine-detail.service";
import { WineService } from "../../services/wine.service";
import { WineConsumeComponent } from "../wine-consume/wine-consume.component";
import { WineDetailListComponent } from "../wine-detail-list/wine-detail-list.component";


@Component({
  selector: 'app-wine',
  standalone: true,
  imports: [RouterModule, CommonModule, FormsModule, WineDetailListComponent, WineConsumeComponent],
  templateUrl: './wine.component.html',
  styleUrl: './wine.component.scss'
})
export class WineComponent {


  constructor(private _sharedService: SharedService, private _userService: UserService, private route: ActivatedRoute, private _wineService: WineService, private _wineDetailService : WineDetailService) { }

  public user: any = {};
  public wineId: string = "";
  public wine: any = {producer:{}};
  public userId: number = 0;
  public isWishlisted: boolean = false;
  public isFavorited: boolean = false;
  public zeroBottlesInCollection: boolean = true;
  public consume = false;
  public fromMyCollection: boolean = false;
  public listOfWine: any[] = [];
  public wineImageCode: string = "";
  public totalWineCount: number = 0;
  public isUpdating: boolean = false;


  ngOnInit() : void {
    this._sharedService.setData({ body: "Wine Detail", image:"", backFunction: true });
    this.wineId = this.route.snapshot.paramMap.get("id") ?? "";
    this.detailService.resetData();

    this._userService.getUser().subscribe({
      next: (response) => {
        this.userId = response.id;
        this.user = response;
      },
      error: (e) => {
        console.error(e);
      }
    });

    this._wineService.getWineDetails((Number(this.wineId))).subscribe({
      next: (response) => {
        this.wine = response;
        this.wineImageCode = encodeURIComponent(this.wine.imagePath);
        console.log(this.wineImageCode);
        this._sharedService.setData({ body: "Wine Detail", image: this.wine.imagePath, backFunction: true });
      },
      error: (e) => {
        console.error(e);
      }
    });

    this._wineService.getSameWineNotConsumed(Number(this.wineId)).subscribe({
      next:(response1) =>{
      // We loop through every wine we have that is simialr to the one
      // on this page in order to set storage/slot properties
      // to display on the front end
      let preRes = response1;
      for (let wine of response1) {
        wine.slots = [];
        this._wineService.getSlotAndStorageForWine(wine.id).subscribe({next: (storageRes)=>{
            storageRes.forEach((slotRow:any)=>{
              let row;
              if(slotRow.slot != null){
                row = {id:slotRow.slot.id,positionX:slotRow.slot.positionX,positionY:slotRow.slot.positionY,storageName: slotRow.storageLocation.name};
              }
              else{
                row = {positionX:null,positionY:null,storageName: slotRow.storageLocation.name};
              }
              wine.storageName = row.storageName;
              wine.slots.push(row);
            });
         }});
      }

      this._wineDetailService.wineList = preRes;
      this.listOfWine = preRes;
      this.detailService.refreshList();

      this.wineService.getBottleCountFromWine(Number(this.wineId)).subscribe( (response) => {
        this.totalWineCount = response;
      });

    },
    error:(error)=>{
      console.log(error);
    }});

    this._wineService.getWineCounts().subscribe({
      next: (response) => {
        this._wineDetailService.wineCounts = response;
      },
      error: (e) => {
        console.log(e);
      }
    });

     this._wineService.getWineWishListStatus(Number(this.wineId)).subscribe({
      next: (response) => {
        this.isWishlisted = response;
      },
      error: (_e) => {
        this.isWishlisted = false;
      }
    });

    this._wineService.getFavoriteStatus(Number(this.wineId)).subscribe({
      next: (response) => {
        console.log(response);
        this.isFavorited = response;
      },
      error: (_e) => {
        this.isFavorited = false;
      }
    });

    if (this._wineDetailService.wineCounts<=0) {
      // This being set to false will
      // repleace the "# bottles in colletion"
      // label with a wishlist button
      this.zeroBottlesInCollection=false;
    }
  }

  get detailService(){
    return this._wineDetailService;
  }

  get wineService() {
    return this._wineService;
  }


  handleConsumeClick() {
    this.consume = !this.consume;
  }

  removeFromFavorites(wineId: number) : void {
    if (!this.isUpdating) {
      this.wineService.postFavoriteRemoval(wineId).subscribe(_response => {
        this.isWishlisted = false;
        this.isFavorited = false;
        this.isUpdating = false;
      });
    }
    this.isUpdating = true;
  }

  addToFavorites(wineId: number) : void {
    if (!this.isUpdating) {
      this.wineService.postNewFavorite(wineId).subscribe(_response => {
        this.isFavorited = true;
        this.isUpdating = false;
      });
    }
    this.isUpdating = true;
  }

  updateWishList(wineId: number, add: boolean) : void {
    if (!this.isUpdating) {
      this.wineService.postWishListUpdate(wineId, add).subscribe(_response => {
        this.isWishlisted = add;
        this.isFavorited = add;
        this.isUpdating = false;
      });
      this.isUpdating = true;
    }
  }


}
