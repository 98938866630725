import { Injectable } from '@angular/core';
import { ScrollService } from './scroll.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private _scrollService: ScrollService) { }
  // list to display in type follow up queries
  public searchResults: any[] = [];
  // list that is used by sorted wine
  // list component to filter and display
  // each wine accordingly
  public sortedWineList: any = [];
  // list that is populated by wines
  // on the typsescript side
  public presortedWineList: any[] = [];
  // list that is populated by producers
  // on the typescript side
  public presortedProducerList: any[] = [];
  // this is a presorted list
  // that combines both wine and producer
  // objects
  public presortedCombinedList: any[] = [];
  // list that will be used to display
  // all producers above the sorted wine
  // component
  public sortedProducerList: any[] = [];
  // the string tied to the current input
  public query: string = "";
  // the user's last query
  public previousQuery: string = "";
  // used to detect extra spaces in a user's query
  public filterExtraSpacesPattern: RegExp = /\s\s+/g;
  // producer list from cache
  public cacheProducerList: any[] = [];
  // wine list fromc ache
  public cacheWineList: any[] = [];

  sortResultList() {

    this.presortedWineList.sort((a,b)=>a.name.localeCompare(b.name));
    this.presortedProducerList.sort((a,b)=>a.name.localeCompare(b.name));
    // we set a new attribute of wine so that the
    // html icon knows which icon to display
    // alongside each result item
    for (let wine of this.presortedWineList) {
      wine.wine=true;
    }
    for (let producer of this.presortedProducerList) {
      producer.wine=false;
    }
    this.sortedWineList = this.presortedWineList.slice();
    this.sortedProducerList = this.presortedProducerList.slice();
    this.presortedCombinedList = [...this.presortedProducerList,...this.presortedWineList];
    this._scrollService.setMax(this.presortedCombinedList.length);
    this.searchResults = this.presortedCombinedList.slice(0, this._scrollService.maxDisplayVal);

  }

  searchFromCache(query: string) {

    for (let producer of this.cacheProducerList) {
      if (this.isLike(producer, query)) {
        this.presortedProducerList.push({name: producer, wine: false});
      }
    }

    for (let wine of this.cacheWineList) {
      if (this.isLike(wine, query)) {
        this.presortedWineList.push({name: wine, wine: true});
      }
    }

    console.log(this.presortedWineList);

    this.sortResultList();

  }

  set setQuery(name: string) {
    this.query = name;
  }

  resetData(){
    this.presortedWineList = [];
    this.presortedProducerList = [];
    this.presortedCombinedList = [];
    this.sortedWineList = [];
    this.sortedProducerList = [];
    this.searchResults = [];
  }

  // this is used in the html side
  // to convert the key of a results
  // object of unknown type to
  // string so that we can index
  // the list of the combined
  // list that contains
  // both wines and producers
  getResultKey(key: unknown): any {
    return String(key);
  }

  // Used to prevent excessive API calls
  // when user types mroe than one space
  // in any part of thier string
  isCurrentQueryDifferent(): boolean {
    let filteredText = this.query.replace(this.filterExtraSpacesPattern, '');
    let afterFilteredText = this.previousQuery.replace(this.filterExtraSpacesPattern, '')
    filteredText = filteredText.trim();
    if (filteredText==afterFilteredText) {
      return false;
    }
    return true;
  }

  isLike(a: string, b: string): boolean {

    const regexStr = b
      .replace(/([.^$|()\\[\]{}+*?\\\\])/g, '\\$1')
      .replace(/%/g, '.*')
      .replace(/_/g, '.')


    const regex = new RegExp(regexStr, 'i');
    return regex.test(a);
  }

  isLikeAnyAttribute(item: any, query: string): boolean {
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        const value = item[key];
        if (typeof value === 'string' && this.isLike(value, query)) {
          return true;  // Found a match in one of the string properties
        }
      }
    }
    return false;  // No match found in any attribute
  }

}
