<div *ngIf="!searchResults">
    @for(wrapperKey of filterService.getWineKeys(); track $index) {
        <div *ngIf="!searchResults" class="card-item gray-card">
            {{wrapperKey}}
        </div>
        <div *ngIf="searchResults && !searchResultsRecentActivity" class="wines-header">
            {{filterService.wineList.length}} Wines
        </div>
        <div *ngIf="searchResults && searchResultsRecentActivity" class="wines-header">
            Recent Activity
        </div>
        @for(wine of filterService.filteredWineList[wrapperKey]; track wine.id) {
            <div *ngIf="!$first && !searchResults" class="dividing-row"></div>
            <div (click)="handleWineClick(wine)" class="card-item wine-card">
                <img class="wine-image" src="{{ wine.imagePath }}" />
                <div class="card-item-middle">
                    <div>
                        <div class="card-item-label wine-card-item-label">
                            {{wine.name}}
                        </div>
                        <div class="gray">
                            {{wine.area}}
                        </div>
                    </div>
                    <div class="wine-color">
                        <div [style.background]="wine.color" class="color-icon"></div>
                        <span class="color-label">{{ wine.varietal }}</span>
                    </div>
                </div>
                <div class="card-item-in-storage" *ngIf="filterService.getWineCount(wine.id) != 0">
                    <div class="notificationBottleCountContainer">
                        <div class="bottleContainer">
                            <div bottleIcon="bottleIcon">
                                <img src="/assets/bottle-small.svg" />
                            </div>
                        </div>
                        <span class="bottleCount">{{filterService.getWineCount(wine.id)}}</span>
                    </div>
                    <div class="volume">({{ filterService.getVolumeString(wine.volume)}})</div>
                </div>
            </div>
        }
    }
</div>

<div *ngIf="(searchResults && searchResultsRecentActivity && filterService.wineListRecentActivity.length!=0)">
        <div class="container recent-activity">
            Recent Activity
        </div>
        @for(wine of filterService.wineListRecentActivity; track wine.id) {
            <div *ngIf="!$first && !searchResults" class="dividing-row"></div>
            <div (click)="handleWineClick(wine)" class="card-item wine-card">
                <img class="wine-image" src="{{ wine.imagePath }}" />
                <div class="card-item-middle">
                    <div>
                        <div class="card-item-label wine-card-item-label">
                            {{wine.name}}
                        </div>
                        <div class="gray">
                            {{wine.area}}
                        </div>
                    </div>
                    <div class="wine-color">
                        <div [style.background]="wine.color" class="color-icon"></div>
                        <span class="color-label">{{ wine.varietal }}</span>
                    </div>
                </div>
                <div class="card-item-in-storage" *ngIf="filterService.getWineCount(wine.id) != 0">
                    <div class="notificationBottleCountContainer">
                        <div class="bottleContainer">
                            <div bottleIcon="bottleIcon">
                                <img src="/assets/bottle-small.svg" />
                            </div>
                        </div>
                        <span class="bottleCount">{{filterService.getWineCount(wine.id)}}</span>
                    </div>
                    <div class="volume">({{ filterService.getVolumeString(wine.volume)}})</div>
                </div>
            </div>
        }
</div>

<div *ngIf="searchResults && !searchResultsRecentActivity">
    <div class="wines-header">
        Wines
    </div>
    @for(wine of filterService.wineListSearchResults; track wine.id) {
        <div *ngIf="!$first && !searchResults" class="dividing-row"></div>
        <div (click)="handleWineClick(wine)" class="card-item wine-card">
            <img class="wine-image" src="{{ wine.imagePath }}" />
            <div class="card-item-middle">
                <div>
                    <div class="card-item-label wine-card-item-label">
                        {{wine.name}}
                    </div>
                    <div class="gray">
                        {{wine.area}}
                    </div>
                </div>
                <div class="wine-color">
                    <div [style.background]="wine.color" class="color-icon"></div>
                    <span class="color-label">{{ wine.varietal }}</span>
                </div>
            </div>
            <div class="card-item-in-storage" *ngIf="filterService.getWineCount(wine.id) != 0">
                <div class="notificationBottleCountContainer">
                    <div class="bottleContainer">
                        <div bottleIcon="bottleIcon">
                            <img src="/assets/bottle-small.svg" />
                        </div>
                    </div>
                    <span class="bottleCount">{{filterService.getWineCount(wine.id)}}</span>
                </div>
                <div class="volume">({{ filterService.getVolumeString(wine.volume)}})</div>
            </div>
        </div>
    }
</div>

