import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxToggleModule } from "@bobbyg603/ngx-toggle";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMaskModule } from "ngx-mask";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-purchase-location-information",
    standalone: true,
    imports: [
        CommonModule,
        TranslatePipe,
        ReactiveFormsModule,
        NgxToggleModule,
        FormsModule,
        NgxMaskModule,
    ],
    templateUrl: "./purchase-location-information.component.html",
    styleUrl: "./purchase-location-information.component.scss",
})
export class PurchaseLocationInformationComponent {
    @Input("form") public form!: FormGroup;
    @Input("purchase") public isPurchaseForm: boolean = false;

    /** Used for translation purposes
    check i18n folder for more info */
    public pagePrefix = "purchase-location-information.";

    public header: string = "Location Information";

    constructor(private translate: TranslateService) {}

    ngOnInnit() {
        if (this.isPurchaseForm) {
            this.header = "Purchase Information";
        }
    }

    /** If the input for teh price field is not
     * formatted in dollars, it will reset
     * the default input to 0.00. */
    onPriceChange() {
        let validateUSD: RegExp = /^\d+(\.\d{2})?$/;
        let price = String(this.form.get("price")?.value);

        // Check if the price input does not match the USD format
        if (!validateUSD.test(price)) {
            this.form.get("price")?.setValue("0.00");
        }
    }
}
