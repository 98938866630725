import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {
  error: string = "";
  success: boolean = false;
  showPasswordType: boolean = false;
  showConfirmPasswordType: boolean = false
  token: string = "";
  email: string = "";

  // form that contains all related info about the inputs
  // for this component
  public form!: FormGroup;

  constructor(private fb: FormBuilder, private authService: AuthService,private route: ActivatedRoute,  private router: Router, private _sharedService: SharedService) { }

  resetPassword() {
    this.error = "";
    if (!this.form.valid) {
      this.form.markAllAsTouched();
    }
    if(!(this.form.get("password")?.value !=="" && this.form.get("confirmPassword")?.value !=="")){
      this.error = "Please enter a new password";
      return;
    }
    if(this.form.get("password")?.value !== this.form.get("confirmPassword")?.value){
      this.error = "Passwords do not match";
      return;
    }
    if (this.form.get("password")?.value.length<8) {
      this.error = "Password must be at least 8 characters";
      return;
    }
    let credentials = {"email": this.email,
      "token": this.token,
      "password":this.form.get("password")?.value,
      "confirmPassword":this.form.get("confirmPassword")?.value};
    this.authService.resetPassword(credentials).subscribe({
      next: (response) => {
        if(response){
          this.success = true;
          this.router.navigate(['/login'], {queryParams: {'msg': "Your password has successfully been reset."}});
        }
        else{
          this.error = "Failed to reset the password";
        }
      },
      error: () => {
        this.error = "Failed to reset the password";
      }
    });

  }

  toggleField(toToggle: keyof typeof this){
    (this[toToggle] as boolean) = !this[toToggle];
  }

  ngOnInit(): void {
    this._sharedService.setData({ title: "Reset Password", body: 'Use the form below to create a new password for your account. Please select a secure, unique password to keep your account protected.<br/>Remember your password? <a style="color:white;" href="/login">Sign in</a>', image: "header3.png"});

    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
    });

    this.route.queryParams.pipe().subscribe({
      next: (param) =>{
        this.token = param["token"];
        this.email = param["email"];
      }
    });
  }
}
