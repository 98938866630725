<div class="mainContent">
<div class="d-flex flex-column">
    <a
    href="#"
    class="manage-button"
    id="dropdownMenuButton-{{ storageId }}"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <img class="d-flex" src="/assets/three-dots.svg" alt="manage" />
  </a>
  <div class="dropdown-menu manage-options">
    <button
      class="dropdown-item"
      [routerLink]="'/storage-detail/' + storageId"
    >
      {{pagePrefix + 'manage-storage-or-location' | translate}}
    </button>
    <div *ngIf="!storageBudList" class="dividing-row low-margin"></div>
    <button *ngIf="!storageBudList"
      class="dropdown-item"
      [routerLink]="
        '/storage-device-config/' +
        storageId +
        locationOrStorageURL +
        '/' +
        location +
        '/' +
        '/setup/' +
        setup
      "
    >
      {{pagePrefix + 'manage-bud-types' | translate}}
    </button>
    <div *ngIf="!storageBudList" class="dividing-row low-margin"></div>
    <button *ngIf="!location && !storageBudList"
      class="dropdown-item"
      [routerLink]="listURL + '' + storageId + '/bud-list'"
    >
      {{pagePrefix + 'manage-sensors-or-buds' | translate}}
    </button>
    <button *ngIf="location"
      class="dropdown-item"
      [routerLink]="listURL + 'browse/' + location + '/' + storageId + '/bud-list'"
    >
      {{pagePrefix + 'manage-sensors-or-buds' | translate}}
    </button>
    <div *ngIf="!location" class="dividing-row low-margin"></div>
    <button *ngIf="storageBudList"
      class="dropdown-item"
    >
      {{pagePrefix + 'connect-hub' | translate}}
    </button>
    <div *ngIf="!location && storageBudList" class="dividing-row low-margin"></div>
    <button *ngIf="!location && !storageBudList"
      class="dropdown-item"
      data-bs-toggle="modal"
      data-bs-target="#exportWinesModal"
    >
      {{pagePrefix + 'export-wine-list' | translate}}
    </button>
    <button *ngIf="storageBudList"
      class="dropdown-item"
      data-bs-toggle="modal"
      data-bs-target="#exportWinesModal"
    >
      {{pagePrefix + 'remap-bud-sensors' | translate}}
    </button>
    <div class="dividing-row low-margin"></div>
    <button
      class="dropdown-item"
      data-bs-toggle="modal"
      data-bs-target="#removeUnitModal"
    >
      {{pagePrefix + 'remove' | translate}}
    </button>
  </div>
  </div>
</div>
