<div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <form (ngSubmit)="resetPassword()"  [formGroup]="form" #formDirective="ngForm">
              <span class="error text-danger">{{error}}</span>
              <div *ngIf="success" class="center" style="text-align:center">
                <span class="text-success">
                    Your password has been successfully changed.
                    <br/><a class="login-return" routerLink="/login">Click here here to sign in.</a>
                </span>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input [disabled]="success" [type]="(showPasswordType ? 'text':'password')" placeholder="At least 8 characters" class="form-control" id="password" formControlName="password" name="email">
                <img [src]="'/assets/' + (showPasswordType ? 'eye.svg':'eye-slash.svg')" (click)='toggleField("showPasswordType")' class="eye-svg" />
              </div>
              <div class="form-group">
                <label for="confirmPassword">Confirm Password</label>
                <input [disabled]="success" [type]="(showConfirmPasswordType ? 'text':'password')" placeholder="At least 8 characters" class="form-control" id="confirmPassword" formControlName="confirmPassword" name="email">
                <img [src]="'/assets/' + (showConfirmPasswordType ? 'eye.svg':'eye-slash.svg')"  (click)='toggleField("showConfirmPasswordType")' class="eye-svg" />
              </div>
              <button [disabled]="success" type="submit" class="btn cc-btn col-12 mt-3">Change Password</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>