import { Routes } from '@angular/router';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { basicGuard } from './guards/basic.guard';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { BrowseWineComponent } from './components/browse-wine/browse-wine.component';
import { BrowseWineListComponent } from './components/browse-wine-list/browse-wine-list.component';
import { CollectionComponent } from './components/collection/collection.component';
import { ActivityComponent } from './components/activity/activity.component';
import { ActivityDetailComponent } from './components/activity-detail/activity-detail.component';
import { ProfileOptionsComponent } from './components/profile-options/profile-options.component';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { AddSubuserComponent } from './components/add-subuser/add-subuser.component';
import { StorageListComponent } from './components/storage-list/storage-list.component';
import { StorageDetailComponent } from './components/storage-detail/storage-detail.component';
import { WineComponent } from './components/wine/wine.component';
import { LocationListComponent } from './components/location-list/location-list.component';
import { LocationDetailComponent } from './components/location-detail/location-detail.component';
import { HeaderOptions } from './components/header/header.component';
import { ScanBarcodeComponent } from './components/scan-barcode/scan-barcode.component';
import { SearchLabelComponent } from './components/search-label/search-label.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { WineImageComponent } from './components/wine-image/wine-image.component';
import { StorageDeviceConfigComponent } from './components/storage-device-config/storage-device-config.component';
import { AddToCollectionComponent } from './components/add-to-collection/add-to-collection.component';
import { ManageNotificationsComponent } from './components/manage-notifications/manage-notifications.component';
import { BudConfigListComponent} from './components/bud-config-list/bud-config-list.component';
import { BudMappingComponent } from './components/bud-mapping/bud-mapping.component';
import { BudDetailComponent } from './components/bud-detail/bud-detail.component';

export const routes: Routes = [
  { path: 'register', component: RegisterComponent,data: { header: HeaderOptions.loggedOutWithImage } },
  { path: 'login', component: LoginComponent,data: { header: HeaderOptions.loggedOutWithImage } },
  { path: 'forgot-password', component: ForgotPasswordComponent,data: { header: HeaderOptions.loggedOutWithImage } },
  { path: 'reset-password', component: ResetPasswordComponent,data: { header: HeaderOptions.loggedOutWithImage } },
  { path: 'browse-wine', component: BrowseWineComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'browse-wine/:category', component: BrowseWineComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'browse-wine/:category/:categoryKey/:selectionText/:value', component: BrowseWineComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'browse-wine-list/:category/:selectionText/:value', component: BrowseWineListComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'browse-wine-list/:category', component: BrowseWineListComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'browse-wine-list/:category/:selectionText/:value/:subValue', component: BrowseWineListComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'search-results', component: SearchResultsComponent, canActivate: [basicGuard], data: {header: HeaderOptions.noImageSmallWineIcon} },
  { path: 'search-results/producer/:name/:id', component: SearchResultsComponent, canActivate: [basicGuard], data: {header: HeaderOptions.noImageSmallBack}},
  { path: 'collection', component: CollectionComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallWineIcon } },
  { path: 'activity', component: ActivityComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallWineIcon } },
  { path: 'activity-detail/:nodeId', component: ActivityDetailComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'home', component: HomeComponent, canActivate: [basicGuard],data: { header: HeaderOptions.loggedInWithImage } },
  { path: 'profile-options', component: ProfileOptionsComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallWineIcon } },
  { path: 'profile-edit', component: ProfileEditComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallWineIcon } },
  { path: 'profile-edit/:id', component: ProfileEditComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallWineIcon } },
  { path: 'complete-profile', component: ProfileEditComponent,data: { header: HeaderOptions.noImageSmallWineIcon } },
  { path: 'add-subuser', component: AddSubuserComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallWineIcon } },
  { path: 'notifications', component: ManageNotificationsComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallWineIcon } },
  { path: 'storage-list', component: StorageListComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallWineIcon } },
  { path: 'storage-list/:id/bud-list', component: BudConfigListComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'storage-list/:id/bud-map', component: BudMappingComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'location-list/browse/:location', component: LocationListComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallWineIcon } },
  { path: 'location-list/browse/:location/:id/bud-list', component: BudConfigListComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'location-list/:id/bud-device/:bud-id', component: BudDetailComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'storage-create', component: StorageDetailComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'location-create', component: LocationDetailComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'storage-detail/:id', component: StorageDetailComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'storage-device-config/:id/location/:location/setup/:set-up', component: StorageDeviceConfigComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'wine/:id', component: WineComponent, canActivate: [basicGuard],data: { header: HeaderOptions.wineDetailWithImage } },
  { path: 'wine/:id/add', component: AddToCollectionComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'wine/:id/:img', component: WineImageComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'scan-barcode', component: ScanBarcodeComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: 'search-label', component: SearchLabelComponent, canActivate: [basicGuard],data: { header: HeaderOptions.noImageSmallBack } },
  { path: '', redirectTo: '/login', pathMatch: 'full',data: { header: HeaderOptions.loggedOutWithImage } }
];
