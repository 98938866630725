import { Component } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { RouterModule } from '@angular/router';
import { ScrollService } from '../../services/scroll.service';
import { CommonModule } from '@angular/common';
import { ActivityService } from '../../services/activity.service';
import { ActivityListComponent } from "../activity-list/activity-list.component";

@Component({
  selector: 'app-activity',
  standalone: true,
  imports: [RouterModule, CommonModule, ActivityListComponent],
  templateUrl: './activity.component.html'
})
export class ActivityComponent {
  constructor(private _sharedService: SharedService,private _activityService: ActivityService,private _scrollService: ScrollService) { }

  public activityList : any[] = [];
  //TODO: Possibly update this so the user has the ability to load more
  //when they hit the end of the list
  public maxNumb: number = 20;

  ngOnInit(): void {
    this._activityService.getActivity(this.maxNumb).subscribe({
      next: (response) => {
        this.activityList = response;
        this._scrollService.setMax(response.length);
      },
      error: (e) => {
        console.error(e);
      }
    });
    this._sharedService.setData({ body: "", title: "Recent Activity", image: "", backFunction: true });
  }
}
