<div class="container d-flex flex-column w-100">
    @for(wine of wineDetailService.refreshWineList; track wine.id) {
        <div *ngIf="!$first" class="dividing-row"></div>
        <div class="container d-flex flex-column card-item wine-card w-100">
            <div class="bottle-part w-100">
                <div class="name-bottle-wrapper">
                    <div class="wine-img-part">
                        <img class="wine-image" src="{{ wine.imagePath }}" alt="wine"/>
                    </div>
                    <div class="card-item-middle">
                        <div>
                            <div class="card-item-label wine-card-item-label">
                                {{wine.vintage}} {{wine.name}}
                            </div>
                            <div class="gray">
                                {{wine.storageName}}
                            </div>
                        </div>
                    </div>
                    <div class="card-item-in-storage" *ngIf="wineDetailService.getWineCount(wine.id) != 0">
                        <div class="notificationBottleCountContainer">
                            <div class="bottleContainer">
                                <div bottleIcon="bottleIcon">
                                    <img src="/assets/bottle-small.svg" alt="bottle" />
                                </div>
                            </div>
                            <span class="bottleCount">{{wineDetailService.getWineCount(wine.id)}}</span>
                        </div>
                        <div class="volume">({{ wineDetailService.getVolumeString(wine.volume)}})</div>
                    </div>
                </div>
                <div class="container d-flex flex-column align-items-center gap-4">
                    @for(slotRow of wine.slots; track $index){
                        <div *ngIf="wineConsumptionPage" class="container slot-button justify-content-center border border-dark gap-2" (click)="handleSlotClick($index, wine.id, slotRow)" [id]="'slot div ' + $index" style="margin-top: 5px;">
                            <input class="slot-radio align-self-center" style="margin-right: 10px;" type="radio" [id]="'slot radio ' + $index + ' ' + slotRow.id + ' ' + wine.id " />
                            <div class="slot-description-row row">
                                <div class="col-auto" style="padding-right: 4px !important">
                                    <label class="wineStorageLocationText">
                                        Located
                                        @if(slotRow.positionX != null){
                                            in Slot {{slotToLetter(slotRow.positionX, slotRow.positionY)}}
                                        }
                                        in the
                                    </label>
                                </div>
                                <div class="col-auto slot-detail-col">
                                    <label class="wineStorageLocationText underline">
                                        {{wine.storageName}}
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="!wineConsumptionPage" class="d-flex justify-content-center flex-column">
                            <div class="row slot-description-row">
                                <div class="col-auto" style="padding-right: 4px !important">
                                    <label class="wineStorageLocationText">
                                        Located
                                        @if(slotRow.positionX != null){
                                            in Slot {{slotToLetter(slotRow.positionX, slotRow.positionY)}}
                                        }
                                        in the
                                    </label>
                                </div>
                                <div class="col-auto slot-detail-co">
                                    <label class="wineStorageLocationText underline">
                                        {{wine.storageName}}
                                    </label>
                                </div>
                            </div>

                        </div>
                    }
                </div>
            </div>
        </div>
    }
</div>
