import { Injectable } from '@angular/core';
import { interval, BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { take, switchMap } from 'rxjs/operators';
import { WineService } from './wine.service';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cacheKey = 'searchCache';
  private updateInterval = environment.cacheUpdateInterval;
  private accountId = -1;
  private cacheSubject = new BehaviorSubject<any>(null); // BehaviorSubject to track the cache state

  constructor(private http: HttpClient, private _wineService: WineService, private _userService: UserService) {
    // Initialize accountId when the user is fetched


    this._userService.getUser().subscribe({
      next: (response: any) => {
        this.accountId = response.id;
        this.updateCache();  // Update cache as soon as user data is available
      },
      error: (e: any) => {
        console.error(e);
      },
    });


    // Periodically update the cache
    interval(this.updateInterval)
      .pipe(take(1))
      .subscribe(() => this.updateCache());
  }

  // Fetch the latest data and update the cache
  updateCache(): void {
    this._wineService.getSearchCache(this.accountId).subscribe((response) => {
      this.setCache(response);
      this.cacheSubject.next(response);
    });
  }

  // Get the cache (returning an Observable)
  getCache(): Observable<any> {
    const cachedData = localStorage.getItem(this.cacheKey);

    // If cache is null, trigger the cache update
    if (!cachedData) {
      console.log('Cache is null, updating now!');
      this.updateCache();
      return this.cacheSubject.asObservable();
    }

    return new Observable((observer) => {
      observer.next(JSON.parse(cachedData));
      observer.complete();
    });
  }

  // Set cache into localStorage (storing any object)
  setCache(data: any): void {
    localStorage.setItem(this.cacheKey, JSON.stringify(data));
  }

  // Clear cache from localStorage
  clearCache(): void {
    localStorage.removeItem(this.cacheKey);
    this.cacheSubject.next(null);
  }
}
