<div class="mainContent">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" #formDirective="ngForm">
    <div class="container wine-source-container" style="padding-bottom: 24px">
      <label class="source-header">{{pagePrefix + "page-subheading-1" | translate}}</label>
      <div class="check-box-container" (click)="handlePurchaseClick()">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="purchaseConsume"
            id="purchaseConsume"
          />
          <label class="form-check-label" for="flexRadioDefault1">
            {{pagePrefix + "wine-source.purchased-and-consumed.label" | translate}}
          </label>
          <br/>
          <label class="input-sublabel"
            >{{pagePrefix + "wine-source.purchased-and-consumed.description" | translate}}</label
          >
        </div>
      </div>
      <div class="check-box-container" (click)="handleCollectionClick()">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="collection"
            id="collection"
          />
          <label class="form-check-label" for="flexRadioDefault1">
            {{pagePrefix + "wine-source.from-my-collection.label" | translate}}
          </label>
          <br/>
          <label class="input-sublabel"
            >{{pagePrefix + "wine-source.from-my-collection.description" | translate}}</label
          >
        </div>
      </div>
    </div>
    <div>
      <div
        *ngIf="purchasedAndConsumed && optionSelected"
        class="container d-flex flex-column gap-3"
      >
        <label class="section-header">{{pagePrefix + "page-subheading-2" | translate}}</label>
        <div class="d-flex flex-column">
          <label class="input-label" for="dropdownMenuButton">{{pagePrefix + "vintage" | translate}}</label>
          <select
            class="form-control mt-2"
            name="vintage"
            formControlName="vintage"
            required="true"
          >
            <option value="" disabled selected>{{pagePrefix + "vintage" | translate}}</option>
            @for(year of vintageDropDownOptions;track $index){
            <option value="{{ year }}">{{ year }}</option>
            }
          </select>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-5 d-flex flex-column">
              <label class="input-label">{{
                pagePrefix + "amount" | translate
              }}</label>
              <div class="d-flex align-items-start mt-2">
                <div (click)="decrementWine()">
                  <img
                    class="number-input-icon"
                    src="assets/subtract.svg"
                    style="cursor: pointer"
                    [alt]="(pagePrefix + 'number-input-icon') | translate"
                  />
                </div>
                <input
                  name="amount"
                  class="quantity-input"
                  type="text"
                  placeholder="1"
                  formControlName="amount"
                />
                <div (click)="incrementWine()">
                  <img
                    class="number-input-icon"
                    src="assets/add.svg"
                    [alt]="(pagePrefix + 'number-input-icon') | translate"
                  />
                </div>
              </div>
            </div>
            <div class="col-7 d-flex flex-column">
              <label class="input-label" for="volume">{{
                pagePrefix + "volume" | translate
              }}</label>
              <select
                class="dropdown-input select-input mt-1"
                name="volume"
                formControlName="volume"
                required="true"
              >
                <option value="0" selected disabled>
                  {{ pagePrefix + "volume" | translate }}
                </option>
                <option value="375">375 ml</option>
                <option value="750">750 ml</option>
                <option value="1500">1500 ml</option>
                <option value="3000">3000 ml</option>
                <option value="6000">6000 ml</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column mt-3">
          <label class="input-label" for="wineNotes">{{
            pagePrefix + "wine-notes.label" | translate
          }}</label>
          <div class="d-flex flex-column mt-1">
            <div class="text-input-container">
              <textarea
                class="form-control"
                name="wineNotes"
                id="wineNotes"
                formControlName="wineNotes"
              ></textarea>
            </div>
          </div>
          <div class="d-flex align-items-start mt-4" style="gap: 5px">
            <ngx-toggle
              id="wineNotesPriv"
              name="wineNotesPriv"
              [disabled]="false"
              formControlName="wineNotesPriv"
            >
            </ngx-toggle>
            <label class="toggle-label">{{
              pagePrefix + "privacy" | translate
            }}</label>
          </div>
        </div>
      </div>
      <div *ngIf="fromMyCollection && optionSelected" style="padding: 6px">
        <label class="container collection-label">{{pagePrefix + "choose-from-collection" | translate}}</label>
        <app-wine-detail-list
          (selectedSlotEmitter)="recieveSlotInfo($event)"
          [wineConsumptionPage]="true"
        ></app-wine-detail-list>
      </div>
      <div
        *ngIf="optionSelected"
        class="container d-flex flex-column gap-3"
        style="padding-top: 6px"
      >
      <div class="dividing-row mb-3 mt-3"></div>
        <label class="section-header">{{pagePrefix + "page-subheading-3" | translate}}</label>
        <div class="d-flex flex-column mt-2">
          <label class="input-label">{{pagePrefix + "rating" | translate}}</label>
          <div>
            <div class="star-container mt-1">
              @for(row of getNumberArray(5); track $index;){
              <div [id]="'star' + row">
                <label (click)="selectedRating = row"
                  ><img
                    [src]="
                      selectedRating >= row
                        ? 'assets/star-filled.svg'
                        : 'assets/star.svg'
                    "
                    [alt]="(pagePrefix + 'star-rating-icon') | translate"
                /></label>
              </div>
              }
            </div>
          </div>
        </div>
        <div class="d-flex flex-column mt-2">
          <label class="input-label" for="tastingNotes">{{pagePrefix + "tasting-notes.label" | translate}}</label>
          <div class="text-input-container mt-1">
            <textarea
              class="form-control"
              id="tastingNotes"
              name="tastingNotes"
              [placeholder]="(pagePrefix + 'tasting-notes.placeholder') | translate"
              formControlName="tastingNotes"
            ></textarea>
          </div>
        </div>
        <div class="d-flex align-items-start mt-2" style="gap: 5px">
          <ngx-toggle
            id="tastingNotesPriv"
            name="tastingNotesPriv"
            [disabled]="false"
            formControlName="tastingNotesPriv"
          >
          </ngx-toggle>
          <label class="toggle-label">{{
            pagePrefix + "privacy" | translate
          }}</label>
        </div>
      </div>
      <div *ngIf="!fromMyCollection" class="dividing-row mt-4"></div>
      <div class="container mt-4">
        <app-purchase-location-information
        *ngIf="!fromMyCollection && optionSelected"
        [purchase]="false"
        [form]="form"
      ></app-purchase-location-information>
      </div>
    </div>
    <div *ngIf="optionSelected" class="container mt-4">
      <button type="submit" class="btn cc-btn w-100">
        {{pagePrefix + "submit-button" | translate}}
      </button>
    </div>
  </form>
</div>
