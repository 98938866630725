import { Component } from "@angular/core";
import { StorageService } from "../../services/storage.service";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { SharedService } from "../../services/shared.service";
import { BudType } from "../../enums/BudType";
import { OptionButtonComponent } from "../option-button/option-button.component";

@Component({
    selector: "app-bud-detail",
    standalone: true,
    imports: [TranslatePipe, RouterModule, OptionButtonComponent],
    templateUrl: "./bud-detail.component.html",
    styleUrl: "./bud-detail.component.scss",
})
export class BudDetailComponent {

    /** The main page prefix for translation .*/
    public pagePrefix: string = "bud-detail."

    /** We use another page prefix of a different page
     * that also already contains a lot of the vocabulary here. */
    public pagePrefix2: string = "storage-unit-buds.";

    /** The id representating the storage location that has
     * this bud device */
    public storageId: number = -1;

    /** The id of the bud node. */
    public budNodeId: number = -1;

    /** The storage location model. */
    public storageLocation: any;

    /** The bud device model. */
    public budDevice: any;

    /** The type of this bud device. e.x. climate controlled. */
    public budDeviceType: string = "";

    /** Whether this page is used for storage location rather
     * than storage units. */
    public location: boolean = true;

    constructor(
        private _route: ActivatedRoute,
        private _storageService: StorageService,
        private translate: TranslateService,
        private _sharedService: SharedService
    ) {}

    ngOnInit() {

        this.translate.get(this.pagePrefix + "page-header").subscribe((title) =>
            this._sharedService.setData({
                body: "",
                title,
                image: "",
                backFunction: true,
            })
        );

        this.storageId = Number(this._route.snapshot.paramMap.get("id") ?? "");
        this.budNodeId = Number(this._route.snapshot.paramMap.get("bud-id") ?? "");

        this._storageService.getStorageLocation(String(this.storageId)).subscribe( (response1) => {
            this.storageLocation = response1;

            this._storageService.getLocationDevice(this.storageId).subscribe( (response2) => {
                this.budDevice = response2;

                // We look for what type of bud device this is
                // such that we can set the bud device type
                // global variable.

                if (this.budDevice.standardBud == 1) {
                    this.budDeviceType = BudType.STANDARD;
                }
                else if (this.budDevice.bottleBud == 1) {
                    this.budDeviceType = BudType.BOTTLE;
                }
                else if (this.budDevice.motionSensorBud == 1) {
                    this.budDeviceType = BudType.MOTION_SENSOR;
                }
                else if (this.budDevice.bulkBud == 1) {
                    this.budDeviceType = BudType.BULK;
                }
                else if (this.budDevice.chillerControlBud == 1) {
                    this.budDeviceType = BudType.CHILLER_CONTROL;
                } else if (this.budDevice.enviornmentalSensorBud==1) {
                    this.budDeviceType = BudType.ENVIORNMENTAL_SENSOR
                } else if (this.budDevice.airScrubberBud==1) {
                    this.budDeviceType = BudType.AIR_SCRUBBER;
                } else if (this.budDevice.humidifierBud==1) {
                    this.budDevice = BudType.HUMIDIFIER;
                }
            })

        })

    }

}
